import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import {
    Button,
    Col,
    Container,
    Form,
    Modal,
    Row,
    Table,
} from "react-bootstrap";
import { addmoneySchema } from "../../../utils/validationsSchema";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { getCardsThunk } from "../../../features/card/cardThunk";
import { cardSelector } from "../../../features/card/cardSelector";
import { authSelector } from "../../../features/auth/authSelector";
import Slider from "react-slick";
import { addTransactionThunk, getTransactionStatsThunk } from "../../../features/transaction/transactionThunk";

export default function AddMoney(props) {
    const dispatch = useDispatch();
    const { userData, token } = useSelector(authSelector);
    const { cards, total, limit, page } = useSelector(cardSelector);
    const [configObject, setConfigObject] = useState({
        headers: {
            Authorization: "Bearer " + token,
        },
    });

    var PaymentsCards = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        centerMode: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    const maskCardNumber = (cardNumber) => {
        // Assuming the card number has 16 digits
        const visibleDigits = 4;
        const maskedNumber = '*'.repeat(cardNumber.length - visibleDigits) + cardNumber.slice(-visibleDigits);
        return maskedNumber;
    };

    const GetCardType = (number) => {
        // visa
        var re = new RegExp("^4");
        if (number.match(re) != null)
            return "Visa";

        // Mastercard 
        // Updated for Mastercard 2017 BINs expansion
        if (/^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(number))
            return "Mastercard";

        // AMEX
        re = new RegExp("^3[47]");
        if (number.match(re) != null)
            return "AMEX";

        // Discover
        re = new RegExp("^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)");
        if (number.match(re) != null)
            return "Discover";

        // Diners
        re = new RegExp("^36");
        if (number.match(re) != null)
            return "Diners";

        // Diners - Carte Blanche
        re = new RegExp("^30[0-5]");
        if (number.match(re) != null)
            return "Diners - Carte Blanche";

        // JCB
        re = new RegExp("^35(2[89]|[3-8][0-9])");
        if (number.match(re) != null)
            return "JCB";

        // Visa Electron
        re = new RegExp("^(4026|417500|4508|4844|491(3|7))");
        if (number.match(re) != null)
            return "Visa Electron";

        return "";
    }

    useEffect(() => {
        getCards();
    }, []);

    const getCards = async () => {
        await dispatch(getCardsThunk({ ...configObject }));
    }

    const formik = useFormik({
        initialValues: {
            card_no: "",
        },
        validationSchema: addmoneySchema,
        onSubmit: async (values, { resetForm }) => {
            try {
                const response = await dispatch(addTransactionThunk({
                    ...configObject, body: {
                        type: 'deposit',
                        via: 'payment',
                        status: 'completed',
                        amount: 1000,
                    }
                }));

                if (response?.payload?.status === 200) {
                    toast.success('Money added successfully');
                    await dispatch(getTransactionStatsThunk({ ...configObject }));
                    props.onClose();
                } else {
                    toast.error('Some problem exist. Please try again.');
                }
            } catch (error) {
                toast.error("Something went wrong.");
            }
        },
    });

    return (
        <Modal show={props.show} onHide={props.onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Select Payment</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Slider {...PaymentsCards} className="mb-4">
                    <div className="px-2">
                        <img
                            src={require("../../../images/payment_card2.png")}
                            alt=""
                            className="w-100"
                        />
                    </div>
                    <div className="px-2">
                        <img
                            src={require("../../../images/payment_card2.png")}
                            alt=""
                            className="w-100"
                        />
                    </div>
                    <div className="px-2">
                        <img
                            src={require("../../../images/payment_card3.png")}
                            alt=""
                            className="w-100"
                        />
                    </div>
                </Slider>
                <Form className="payment_method_form">
                    {
                        total > 0 && cards.map((item) => {
                            return (<Form.Group className="mb-3" controlId={item?._id} key={item?._id}>
                                <Form.Control type="radio" name="card_no" value={item?._id} onChange={formik.handleChange} />
                                <Form.Label>
                                    <Row className="align-items-center h-100">
                                        <Col md={4}>
                                            {GetCardType(item?.card_no)}
                                            {/* <img src={require(`../../../images/${GetCardType(item?.card_no)}.png`)} alt="" /> */}
                                            {/* <img src={require("../../../images/paypal.png")} alt="" />
                                        <img src={require("../../../images/visaCard.png")} alt="" /> */}
                                        </Col>
                                        <Col md={6}>
                                            <span>{maskCardNumber(item?.card_no)}</span>
                                        </Col>
                                    </Row>
                                </Form.Label>
                                <span style={{ color: "red" }}>
                                    {formik.errors.card_no ? (
                                        <div>{formik.errors.card_no} </div>
                                    ) : null}{" "}
                                </span>
                            </Form.Group>)
                        })
                    }
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" className="w-100" onClick={formik.handleSubmit}>
                    Make Payment
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
