import React, { useState } from "react";
import AdminSidebar from "../AdminSidebar";
import DashboardHeader from "../DashboardHeader";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { authSelector } from "../../../features/auth/authSelector";
import { useFormik } from "formik";
import { updateProfileThunk } from "../../../features/auth/authThunk";
import { userUpdateProfileValidationSchema } from "../../../utils/validationsSchema";
import AdminNavBar from "../AdminNavBar";
import { Link, useNavigate, useLocation } from "react-router-dom";
import SettingData from "./settingData";
import { toast } from "react-hot-toast";

function ProfilePage() {
  const [showContent, setShowContent] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const toggleSidebar = () => setShowContent(!showContent);
  const { userData, token } = useSelector(authSelector);
  const dispatch = useDispatch();
  const [configObject, setConfigObject] = useState({
    headers: {
      Authorization: "Bearer " + token,
    },
  });

  const formik = useFormik({
    initialValues: {
      full_name: userData?.full_name,
      email: userData?.email,
      address: userData?.address,
      city: userData?.city,
      _id: userData?._id,
    },
    onSubmit: async (values) => {
      let payload = {
        token,
        ...values,
      };
      const resp = await dispatch(updateProfileThunk({ ...configObject, body: payload }));
      if (resp?.payload?.status === 200) {
        toast.success('user details updated successfully');
      } else {
        toast.error(resp?.payload?.response?.data?.message);
      }
    },
    validationSchema: userUpdateProfileValidationSchema,
    validateOnMount: true,
  });

  return (
    <>
      <div
        className={
          showContent ? "dashboard-main content-visible" : "dashboard-main"
        }
      >
        {/* <div>
          <AdminSidebar />
        </div> */}
        <div className="dashboard-body">
          <DashboardHeader toggleSidebar={toggleSidebar} />
          <Container>
            <AdminNavBar />
            <div className="dashboard-content pofile_page p-2 p-md-4">
              <Row>
                <Col lg={5} className="mb-4">
                  <div className="dashboardCard overflow-hidden">
                    <SettingData />
                    <div className="p-4 text-center">
                      <Link className={`${location.pathname === "/profile" ? "bg-black text-white" : "text-dark"}  btn border-0 py-2 px-4 mb-4 `} to="/profile">
                        Basic Details
                      </Link>
                      <div className="d-flex align-items-center flex-column gap-4">
                        <Link className={`text-muted fw-semibold`} to="/profileComplete"
                          state={{ step: 1 }}>
                          Documents
                        </Link>
                        <Link className={`text-muted fw-semibold`} to="/profileComplete"
                          state={{ step: 2 }}>
                          Account Settings
                        </Link>
                        <Link className={`text-muted fw-semibold`} to="/changepassword">Password</Link>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg={7} className="investor-profile-wrapper p-0">
                  <div className="dashboardCard p-4 mb-4 ">
                    <Form onSubmit={formik.handleSubmit}>
                      <Form.Group className="mb-3">
                        <Form.Control
                          type="text"
                          placeholder="User Name"
                          name="full_name"
                          onChange={formik.handleChange}
                          value={formik.values.full_name}
                        />
                        <span style={{ color: "red" }}>
                          {formik.errors.full_name ? (
                            <div>{formik.errors.full_name} </div>
                          ) : null}{" "}
                        </span>
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Control
                          type="text"
                          placeholder="Email Address"
                          name="email"
                          onChange={formik.handleChange}
                          value={formik.values.email}
                        />
                        <span style={{ color: "red" }}>
                          {formik.errors.email ? (
                            <div>{formik.errors.email} </div>
                          ) : null}{" "}
                        </span>
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Control
                          type="text"
                          placeholder="Address"
                          name="address"
                          onChange={formik.handleChange}
                          value={formik.values.address}
                        />
                        <span style={{ color: "red" }}>
                          {formik.errors.address ? (
                            <div>{formik.errors.address} </div>
                          ) : null}{" "}
                        </span>
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Control
                          type="text"
                          placeholder="City"
                          name="city"
                          onChange={formik.handleChange}
                          value={formik.values.city}
                        />
                        <span style={{ color: "red" }}>
                          {formik.errors.city ? (
                            <div>{formik.errors.city} </div>
                          ) : null}{" "}
                        </span>
                      </Form.Group>
                      <Button type="submit" className="px-4">
                        Save
                      </Button>
                    </Form>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
}

export default ProfilePage;
