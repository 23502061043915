import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

//const api_url = "https://crowdfina-backend.iapplabz.co.in/api"
const api_url = process.env.REACT_APP_API_URL_DEV;

//login thunk
export const loginThunk = createAsyncThunk("auth/login", async (body) => {
  try {
    const data = await axios.post(`${api_url}/login`, body);

    return data;
  } catch (error) {
    return error;
  }
});
// sign up thunk
export const registerThunk = createAsyncThunk("auth/register", async (body) => {
  try {
    const data = await axios.post(`${api_url}/signup`, body);

    return data;
  } catch (error) {
    return error;
  }
});
// forgot password
export const forgotPasswordThunk = createAsyncThunk(
  "auth/forgotPassword",
  async (body) => {
    try {
      const data = await axios.post(`${api_url}/forgotPassword`, body);
      return data;
    } catch (error) {
      return error;
    }
  }
);

// reset password
export const resetPasswordThunk = createAsyncThunk(
  "auth/resetPassword",
  async (body) => {
    try {
      const data = await axios.post(`${api_url}/resetPassword`, body);
      return data;
    } catch (error) {
      return error;
    }
  }
);

export const changePasswordThunk = createAsyncThunk(
  "auth/changePassword",
  async ({ body, headers }) => {
    try {
      const config = {
        headers: {
          Authorization: headers.Authorization,
        },
      };
      const data = await axios.post(`${api_url}/changepassword`, body, config);
      return data;
    } catch (error) {
      return error;
    }
  }
);

//update profile
export const updateProfileThunk = createAsyncThunk(
  "auth/updateProfile",
  async ({ body, headers }) => {
    try {
      const config = {
        headers: {
          Authorization: headers.Authorization,
        },
      };
      const data = await axios.post(`${api_url}/updateProfile`, body, config);
      return data;
    } catch (error) {
      return error;
    }
  }
);
//change password
// export const changePasswordThunk = createAsyncThunk(
//   "auth/updatePass",
//   async ({ body, headers }) => {
//     const config = {
//       headers: {
//         Authorization: headers.Authorization,
//       },
//     };
//     try {
//       const data = await axios.post(
//         `${api_url}/users/changePassword`,
//         body,
//         config
//       );
//       return data;
//     } catch (error) {
//       return error;
//     }
//   }
// );

export const subscribeThunk = createAsyncThunk("subscribe", async (body) => {
  try {
    const data = await axios.post(`${api_url}/subscribe`, body);

    return data;
  } catch (error) {
    return error;
  }
});

export const verifyEmailThunk = createAsyncThunk(
  "auth/verifyemail",
  async (body) => {
    try {
      const data = await axios.post(`${api_url}/verifyemail`, body);
      return data;
    } catch (error) {
      return error;
    }
  }
);

export const updateDetailsThunk = createAsyncThunk(
  "auth/updateDetails",

  async ({ body, headers }) => {
    const config = {
      headers: {
        Authorization: headers.Authorization,
      },
    };
    try {
      const data = await axios.put(`${api_url}/updateUserDetails`, body, config);
      return data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const logoutThunk = createAsyncThunk("auth/logout", async (body) => {
  localStorage.removeItem("userToken");
  localStorage.removeItem("user");
  return true;
});

export const socialLoginThunk = createAsyncThunk("auth/socialLogin", async (body) => {
  try {
    const data = await axios.post(`${api_url}/socialLogin`, body);
    return data;
  } catch (error) {
    return error;
  }
});

export const updateImageThunk = createAsyncThunk(
  "auth/updateImage",
  async ({ body, headers }) => {
    const config = {
      headers: {
        Authorization: headers.Authorization,
        "Content-Type": "multipart/form-data",
      },
    };
    try {
      const data = await axios.post(`${api_url}/changeProfileImage`, body, config);
      return data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getProfileThunk = createAsyncThunk(
  "user/profile",
  async ({ headers }) => {
    const config = {
      headers: {
        Authorization: headers.Authorization,
      },
    };
    try {
      const data = await axios.get(`${api_url}/userProfile`, config);
      return data;
    } catch (error) {
      console.log(error);
    }
  }
);