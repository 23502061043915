import React, { useEffect, useState } from "react";
import AdminSidebar from "../AdminSidebar";
import DashboardHeader from "../DashboardHeader";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { authSelector } from "../../../features/auth/authSelector";
import { useFormik } from "formik";
import { updateProfileThunk } from "../../../features/auth/authThunk";
import { userUpdateProfileValidationSchema } from "../../../utils/validationsSchema";
import AdminNavBar from "../AdminNavBar";
import StepOne from "../../investor/StepOne";
import StepTwo from "../../investor/StepTwo";
import SettingData from "../ProfilePage/settingData";

function ProfilePageTwo(props) {
  const [activeStep, setActiveStep] = useState(1);
  const [showContent, setShowContent] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const toggleSidebar = () => setShowContent(!showContent);
  const { userData, token } = useSelector(authSelector);
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      full_name: userData?.full_name,
      email: userData?.email,
      address: userData?.address,
      city: userData?.city,
      _id: userData?._id,
    },
    onSubmit: async (values) => {
      console.log(values);
      let payload = {
        token,
        ...values,
      };
      await dispatch(updateProfileThunk(payload));
    },
    validationSchema: userUpdateProfileValidationSchema,
    validateOnMount: true,
  });

  useEffect(() => {
    // if (userData?.address_proof?.length > 0 && userData?.verification_document?.length > 0) {
    //   setActiveStep(2)
    // }
  }, [userData])

  //console.log(location)
  //console.log(userData?.verification_document)

  return (
    <>
      <div
        className={
          showContent ? "dashboard-main content-visible" : "dashboard-main"
        }
      >
        {/* <div>
          <AdminSidebar />
        </div> */}
        <div className="dashboard-body">
          <DashboardHeader toggleSidebar={toggleSidebar} />
          <Container>
            <AdminNavBar />
            <div className="dashboard-content pofile_page p-2 p-md-4">
              <Row>
                <Col lg={5} className="mb-4">
                  <div className="dashboardCard overflow-hidden">
                    <SettingData />
                    <div className="p-4 text-center">
                      <Link className={`${location.pathname === "/profile" ? "bg-black text-white" : "text-dark"}  btn border-0 py-2 px-4 mb-4 `} to="/profile">
                        Basic Details
                      </Link>
                      <div className="d-flex align-items-center flex-column gap-4">
                        <Link className={`${location.pathname === "/profileComplete" && activeStep == 1 ? "bg-black text-white" : "text-dark"} btn border-0 py-2 px-4 `} onClick={() => setActiveStep(1)}>
                          Documents
                        </Link>
                        <Link className={`${location.pathname === "/profileComplete" && activeStep == 2 ? "bg-black text-white" : "text-dark"} btn border-0 py-2 px-4 `} onClick={() => setActiveStep(2)}>
                          Account Settings
                        </Link>
                        <Link className={`${location.pathname === "/changepassword" ? "bg-black text-white" : "text-dark"} btn border-0 py-2 px-4 `} to="/changepassword">Password</Link>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg={7} className="investor-profile-wrapper">
                  {/* <MultiStep activeStep={activeStep}> */}
                  {activeStep === 1 ? (
                    <StepOne
                      title="Step 1"
                      onNextStep={() => setActiveStep(2)}
                    />
                  ) : (
                    <StepTwo title="Step 2" />
                  )}

                  {/* </MultiStep> */}
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
}

export default ProfilePageTwo;
