import React from "react";
import {
  Breadcrumb,
  Button,
  Col,
  Container,
  Nav,
  Navbar,
  Row,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Header from "../common/header";
import Footer from "../common/footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faComment,
  faUser,
} from "@fortawesome/free-solid-svg-icons";

function Blog() {
  return (
    <>
      <Header />

      {/* ====== common banner ======= */}
      <div className="static_banner position-relative">
        <Container>
          <div className="text-center">
            <h3>Blog</h3>
            <p>
              With an unwavering commitment to excellence, CrowdFina is on an{" "}
              <br /> odyssey to revolutionize the landscape of real estate.
            </p>
          </div>
          <Breadcrumb className="pages-breadcrumbs">
            <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
            <Breadcrumb.Item active>Blog</Breadcrumb.Item>
          </Breadcrumb>
        </Container>
        <img src={require("../../images/banner-wave.png")} alt="wave" className="static-banner-shape"  />
      </div>

      <div className="common-padding">
        <Container>
          <Row>
            <Col lg={6} className="mb-3">
              <div className="p-3 position-relative">
                <div className="blog_img">
                  <img
                    src={require("../../images/faq.png")}
                    alt="blog"
                    className="w-100"
                  />
                </div>
                <div className="blog_content_wrapper p-4 mx-auto">
                  <ul className="ps-0 list-unstyled d-flex flex-wrap gap-4">
                    <li className="d-flex gap-2 align-items-center">
                      <div className="d-flex align-items-center justify-content-center admin_icon">
                        <FontAwesomeIcon icon={faUser} />
                      </div>
                      <p className="mb-0">Admin</p>
                    </li>
                    <li className="d-flex gap-2 align-items-center">
                      <div className="d-flex align-items-center justify-content-center admin_icon">
                        <FontAwesomeIcon icon={faCalendar} />
                      </div>
                      <p className="mb-0">20 December 2023</p>
                    </li>
                    <li className="d-flex gap-2 align-items-center">
                      <div className="d-flex align-items-center justify-content-center admin_icon">
                        <FontAwesomeIcon icon={faComment} />
                      </div>
                      <p className="mb-0">Comment</p>
                    </li>
                  </ul>
                  <h4 className="blog_heading pe-4">
                    We Help You to GrowYour Business Quickly
                  </h4>
                  <p className="mb-0 blog_description">
                    Venessa has captured some of the most photojournalistic
                    images with nearly every step, my microspikes slipped off
                    the soles of my chukka boots.{" "}
                  </p>

                  <Link to={"/blog-detail"} className="blog_btn px-4 mt-4 btn btn-primary">Read More</Link>
                </div>
              </div>
            </Col>
            <Col lg={6} className="mb-3">
              <div className="p-3 position-relative">
                <div className="blog_img">
                  <img
                    src={require("../../images/faq.png")}
                    alt="blog"
                    className="w-100"
                  />
                </div>
                <div className="blog_content_wrapper p-4 mx-auto">
                  <ul className="ps-0 list-unstyled d-flex flex-wrap gap-4">
                    <li className="d-flex gap-2 align-items-center">
                      <div className="d-flex align-items-center justify-content-center admin_icon">
                        <FontAwesomeIcon icon={faUser} />
                      </div>
                      <p className="mb-0">Admin</p>
                    </li>
                    <li className="d-flex gap-2 align-items-center">
                      <div className="d-flex align-items-center justify-content-center admin_icon">
                        <FontAwesomeIcon icon={faCalendar} />
                      </div>
                      <p className="mb-0">20 December 2023</p>
                    </li>
                    <li className="d-flex gap-2 align-items-center">
                      <div className="d-flex align-items-center justify-content-center admin_icon">
                        <FontAwesomeIcon icon={faComment} />
                      </div>
                      <p className="mb-0">Comment</p>
                    </li>
                  </ul>
                  <h4 className="blog_heading pe-4">
                    We Help You to GrowYour Business Quickly
                  </h4>
                  <p className="mb-0 blog_description">
                    Venessa has captured some of the most photojournalistic
                    images with nearly every step, my microspikes slipped off
                    the soles of my chukka boots.{" "}
                  </p>

                  <Link to={"/blog-detail"} className="blog_btn px-4 mt-4 btn btn-primary">Read More</Link>
                </div>
              </div>
            </Col>
            <Col lg={6} className="mb-3">
              <div className="p-3 position-relative">
                <div className="blog_img">
                  <img
                    src={require("../../images/faq.png")}
                    alt="blog"
                    className="w-100"
                  />
                </div>
                <div className="blog_content_wrapper p-4 mx-auto">
                  <ul className="ps-0 list-unstyled d-flex flex-wrap gap-4">
                    <li className="d-flex gap-2 align-items-center">
                      <div className="d-flex align-items-center justify-content-center admin_icon">
                        <FontAwesomeIcon icon={faUser} />
                      </div>
                      <p className="mb-0">Admin</p>
                    </li>
                    <li className="d-flex gap-2 align-items-center">
                      <div className="d-flex align-items-center justify-content-center admin_icon">
                        <FontAwesomeIcon icon={faCalendar} />
                      </div>
                      <p className="mb-0">20 December 2023</p>
                    </li>
                    <li className="d-flex gap-2 align-items-center">
                      <div className="d-flex align-items-center justify-content-center admin_icon">
                        <FontAwesomeIcon icon={faComment} />
                      </div>
                      <p className="mb-0">Comment</p>
                    </li>
                  </ul>
                  <h4 className="blog_heading pe-4">
                    We Help You to GrowYour Business Quickly
                  </h4>
                  <p className="mb-0 blog_description">
                    Venessa has captured some of the most photojournalistic
                    images with nearly every step, my microspikes slipped off
                    the soles of my chukka boots.{" "}
                  </p>

                  <Link to={"/blog-detail"} className="blog_btn px-4 mt-4 btn btn-primary">Read More</Link>
                </div>
              </div>
            </Col>
            <Col lg={6} className="mb-3">
              <div className="p-3 position-relative">
                <div className="blog_img">
                  <img
                    src={require("../../images/faq.png")}
                    alt="blog"
                    className="w-100"
                  />
                </div>
                <div className="blog_content_wrapper p-4 mx-auto">
                  <ul className="ps-0 list-unstyled d-flex flex-wrap gap-4">
                    <li className="d-flex gap-2 align-items-center">
                      <div className="d-flex align-items-center justify-content-center admin_icon">
                        <FontAwesomeIcon icon={faUser} />
                      </div>
                      <p className="mb-0">Admin</p>
                    </li>
                    <li className="d-flex gap-2 align-items-center">
                      <div className="d-flex align-items-center justify-content-center admin_icon">
                        <FontAwesomeIcon icon={faCalendar} />
                      </div>
                      <p className="mb-0">20 December 2023</p>
                    </li>
                    <li className="d-flex gap-2 align-items-center">
                      <div className="d-flex align-items-center justify-content-center admin_icon">
                        <FontAwesomeIcon icon={faComment} />
                      </div>
                      <p className="mb-0">Comment</p>
                    </li>
                  </ul>
                  <h4 className="blog_heading pe-4">
                    We Help You to GrowYour Business Quickly
                  </h4>
                  <p className="mb-0 blog_description">
                    Venessa has captured some of the most photojournalistic
                    images with nearly every step, my microspikes slipped off
                    the soles of my chukka boots.{" "}
                  </p>

                  <Link to={"/blog-detail"} className="blog_btn px-4 mt-4 btn btn-primary">Read More</Link>
                </div>
              </div>
            </Col>
            <Col lg={6} className="mb-3">
              <div className="p-3 position-relative">
                <div className="blog_img">
                  <img
                    src={require("../../images/faq.png")}
                    alt="blog"
                    className="w-100"
                  />
                </div>
                <div className="blog_content_wrapper p-4 mx-auto">
                  <ul className="ps-0 list-unstyled d-flex flex-wrap gap-4">
                    <li className="d-flex gap-2 align-items-center">
                      <div className="d-flex align-items-center justify-content-center admin_icon">
                        <FontAwesomeIcon icon={faUser} />
                      </div>
                      <p className="mb-0">Admin</p>
                    </li>
                    <li className="d-flex gap-2 align-items-center">
                      <div className="d-flex align-items-center justify-content-center admin_icon">
                        <FontAwesomeIcon icon={faCalendar} />
                      </div>
                      <p className="mb-0">20 December 2023</p>
                    </li>
                    <li className="d-flex gap-2 align-items-center">
                      <div className="d-flex align-items-center justify-content-center admin_icon">
                        <FontAwesomeIcon icon={faComment} />
                      </div>
                      <p className="mb-0">Comment</p>
                    </li>
                  </ul>
                  <h4 className="blog_heading pe-4">
                    We Help You to GrowYour Business Quickly
                  </h4>
                  <p className="mb-0 blog_description">
                    Venessa has captured some of the most photojournalistic
                    images with nearly every step, my microspikes slipped off
                    the soles of my chukka boots.{" "}
                  </p>

                  <Link to={"/blog-detail"} className="blog_btn px-4 mt-4 btn btn-primary">Read More</Link>
                </div>
              </div>
            </Col>
            <Col lg={6} className="mb-3">
              <div className="p-3 position-relative">
                <div className="blog_img">
                  <img
                    src={require("../../images/faq.png")}
                    alt="blog"
                    className="w-100"
                  />
                </div>
                <div className="blog_content_wrapper p-4 mx-auto">
                  <ul className="ps-0 list-unstyled d-flex flex-wrap gap-4">
                    <li className="d-flex gap-2 align-items-center">
                      <div className="d-flex align-items-center justify-content-center admin_icon">
                        <FontAwesomeIcon icon={faUser} />
                      </div>
                      <p className="mb-0">Admin</p>
                    </li>
                    <li className="d-flex gap-2 align-items-center">
                      <div className="d-flex align-items-center justify-content-center admin_icon">
                        <FontAwesomeIcon icon={faCalendar} />
                      </div>
                      <p className="mb-0">20 December 2023</p>
                    </li>
                    <li className="d-flex gap-2 align-items-center">
                      <div className="d-flex align-items-center justify-content-center admin_icon">
                        <FontAwesomeIcon icon={faComment} />
                      </div>
                      <p className="mb-0">Comment</p>
                    </li>
                  </ul>
                  <h4 className="blog_heading pe-4">
                    We Help You to GrowYour Business Quickly
                  </h4>
                  <p className="mb-0 blog_description">
                    Venessa has captured some of the most photojournalistic
                    images with nearly every step, my microspikes slipped off
                    the soles of my chukka boots.{" "}
                  </p>

                  <Link to={"/blog-detail"} className="blog_btn px-4 mt-4 btn btn-primary">Read More</Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      {/* ===== image gallery section ====== */}
      <div className="common-padding pt-0">
        <Container fluid>
          <div className="grid-gallery">
            <ul className="list-unstyled d-flex flex-wrap justify-content-between">
              <li>
                <div className="grid-item">
                  <img
                    src={require("../../images/gallary1.png")}
                    alt="gallary"
                  />
                </div>
                <div className="grid-item">
                  <img
                    src={require("../../images/gallary2.png")}
                    alt="gallary"
                  />
                </div>
              </li>
              <li>
                <div className="grid-item">
                  <img
                    src={require("../../images/gallary3.png")}
                    alt="gallary"
                  />
                </div>
                <div className="grid-item">
                  <img
                    src={require("../../images/gallary1.png")}
                    alt="gallary"
                  />
                </div>
              </li>
              <li>
                <div className="grid-item">
                  <img
                    src={require("../../images/gallary1.png")}
                    alt="gallary"
                  />
                </div>
                <div className="grid-item">
                  <img
                    src={require("../../images/gallary2.png")}
                    alt="gallary"
                  />
                </div>{" "}
              </li>
              <li>
                <div className="grid-item">
                  <img
                    src={require("../../images/gallary3.png")}
                    alt="gallary"
                  />
                </div>
                <div className="grid-item">
                  <img
                    src={require("../../images/gallary1.png")}
                    alt="gallary"
                  />
                </div>
              </li>
              <li>
                <div className="grid-item">
                  <img
                    src={require("../../images/gallary1.png")}
                    alt="gallary"
                  />
                </div>
                <div className="grid-item">
                  <img
                    src={require("../../images/gallary2.png")}
                    alt="gallary"
                  />
                </div>
              </li>
              <li>
                <div className="grid-item">
                  <img
                    src={require("../../images/gallary3.png")}
                    alt="gallary"
                  />
                </div>
                <div className="grid-item">
                  <img
                    src={require("../../images/gallary1.png")}
                    alt="gallary"
                  />
                </div>
              </li>
            </ul>
          </div>
        </Container>
      </div>
      {/* ===== image gallery section ====== */}

      <Footer />
    </>
  );
}

export default Blog;
