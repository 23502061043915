import * as Yup from "yup";

export const loginValidationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address.")
    .matches(
      /^[a-zA-Z0-9._@-]*$/,
      "Can only contains English alphabet characters, periods, dashes, underscores, and the @ sign"
    )
    .required("Required!"),
  password: Yup.string()
    .matches(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&*!])[A-Za-z\d@#$%^&*!]{8,}$/,
      "Password must contain at least one digit, one lowercase letter, one uppercase letter, and one special character"
    )
    .required("Required!"),
});
export const signupValidationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address.")
    .matches(
      /^[a-zA-Z0-9._@-]*$/,
      "Can only contains English alphabet characters, periods, dashes, underscores, and the @ sign"
    )
    .required("Required!"),
  password: Yup.string()
    .matches(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&*!])[A-Za-z\d@#$%^&*!]{8,}$/,
      "Password must contain at least one digit, one lowercase letter, one uppercase letter, and one special character"
    )
    .required("Required!"),
  username: Yup.string().required("Required!"),
  full_name: Yup.string().required("Required!"),
});
export const userUpdateProfileValidationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address.")
    .matches(
      /^[a-zA-Z0-9._@-]*$/,
      "Can only contains English alphabet characters, periods, dashes, underscores, and the @ sign"
    )
    .required("Required!"),
  address: Yup.string().required("Required!"),
  full_name: Yup.string().required("Required!"),
  city: Yup.string().required("Required!"),
});
export const forgotSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address.")
    .matches(
      /^[a-zA-Z0-9._@-]*$/,
      "Can only contains English alphabet characters, periods, dashes, underscores, and the @ sign"
    )
    .required("Required!"),
});
export const resetPasswordSchema = Yup.object({
  newPassword: Yup.string()
    .matches(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&*!])[A-Za-z\d@#$%^&*!]{8,}$/,
      "Password must contain at least one digit, one lowercase letter, one uppercase letter, and one special character"
    )
    .required("Required!"),
  confirmPassword: Yup.string()
    .required("Required!")
    .oneOf([Yup.ref("newPassword"), null], "Passwords must match."),
});
export const changePasswordSchema = Yup.object({
  oldPassword: Yup.string().required("Required!"),
  newPassword: Yup.string()
    .matches(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&*!])[A-Za-z\d@#$%^&*!]{8,}$/,
      "Password must contain at least one digit, one lowercase letter, one uppercase letter, and one special character"
    )
    .required("Required!"),
  confirmPassword: Yup.string()
    .required("Required!")
    .oneOf([Yup.ref("newPassword"), null], "Passwords must match."),
});
export const subscriberSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address.")
    .matches(
      /^[a-zA-Z0-9._@-]*$/,
      "Can only contains English alphabet characters, periods, dashes, underscores, and the @ sign"
    )
    .required("Required!"),
});

export const investProfileStep2Schema = Yup.object({

  investment_horizon: Yup.string().required("Required!"),
  sustainability: Yup.string().required("Required!"),
  ability_to_bear_losses: Yup.string().required("Required!"),
  investment_experience: Yup.string().required("Required!"),
  professional_status: Yup.string().required("Required!"),
  annual_income_range: Yup.string().required("Required!"),
  estimated_total_wealth: Yup.string().required("Required!"),
  investment_objectives: Yup.array().of(Yup.string().required('Required!')).required('Required!'),
  //income_currency: Yup.string().required("Required!"),
});

export const addCardSchema = Yup.object({
  card_no: Yup.string().matches(/^\d{16}$/, 'Must be a 16-digit numeric card number').required("Required!"),
  holder_name: Yup.string().required("Required!"),
  exp_date: Yup.string().matches(/^(0[1-9]|1[0-2])\/\d{4}$/, 'Invalid date format (MM/YYYY)').required("Required!"),
  cvv: Yup.string().matches(/^\d{3}$/, 'Must be a 3-digit numeric card number').required("Required!"),
});

export const addmoneySchema = Yup.object({
  card_no: Yup.string().required("Required!"),
});