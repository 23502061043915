import { faEnvelopeOpen, faImage } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import { Button, Col, Form } from "react-bootstrap";
import { useFormik } from "formik";
import { updateDetailsThunk } from "../../features/auth/authThunk";
import { authSelector } from "../../features/auth/authSelector";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-hot-toast";
import * as Yup from "yup";

function StepOne(props) {
  const [verificationDocs, setVerificationDocs] = useState([]);
  const [addressDocs, setAddressDocs] = useState([]);
  const { userData, token } = useSelector(authSelector);
  const dispatch = useDispatch();

  const [configObject, setConfigObject] = useState({
    headers: {
      Authorization: "Bearer " + token,
    },
  });

  const investProfileStep1Schema = Yup.object({
    email: Yup.string()
      .email("Invalid email address.")
      .matches(
        /^[a-zA-Z0-9._@-]*$/,
        "Can only contains English alphabet characters, periods, dashes, underscores, and the @ sign"
      )
      .required("Required!"),
    address: Yup.string().required("Required!"),
    verification_document: (!userData?.verification_document) ? Yup.array().of(Yup.string().required('Required!')) : Yup.array().of(Yup.string()),
    address_proof: (!userData?.address_proof) ? Yup.array().of(Yup.string().required('Required!')) : Yup.array().of(Yup.string()),
  });

  const formik = useFormik({
    initialValues: {
      email: userData?.email ?? "",
      address: userData?.address ?? "",
      sameAsAddressProof: false,
      ///verification_document: [""],
      //address_proof: [""],
    },
    validationSchema: investProfileStep1Schema,
    onSubmit: async (values) => {
      try {

        if (verificationDocs.length > 0) {
          let formData = new FormData();
          formData.append("folder", "users");
          for (const file of verificationDocs) {
            formData.append("image", file);
          }
          let resp = await axios.post(
            `${process.env.REACT_APP_API_URL_DEV}/uploadImage`,
            formData,
            {
              ...configObject,
              "Content-Type": "multipart/form-data",
            }
          );

          if (resp?.status === 200) {
            values.verification_document = resp?.data?.body?.filenames;
          }
        }

        if (!values.sameAsAddressProof && addressDocs.length > 0) {
          let formData1 = new FormData();
          formData1.append("folder", "users");
          for (const file of addressDocs) {
            formData1.append("image", file);
          }
          let resp1 = await axios.post(
            `${process.env.REACT_APP_API_URL_DEV}/uploadImage`,
            formData1,
            {
              headers: {
                Authorization: "Bearer " + token,
                "Content-Type": "multipart/form-data",
              },
            }
          );
          if (resp1?.status === 200) {
            values.address_proof = resp1?.data?.body?.filenames;
          }
        }

        values.address_proof = values.sameAsAddressProof
          ? values.verification_document
          : values.address_proof;

        const resp2 = await dispatch(
          updateDetailsThunk({ ...configObject, body: values })
        );
        if (resp2?.payload?.status === 200) {
          props.onNextStep();
        }
      } catch (error) {
        toast.error("Some problem exist, Please try again");
      }
    },
  });

  const handleVerification = (e) => {
    let files = [];
    let attachments = [];
    for (const file of e.target.files) {
      files.push(file.name);
      attachments.push(file);
    }
    formik.setFieldValue("verification_document", files);
    setVerificationDocs(attachments);
  };

  const handleAddressProof = (e) => {
    let files = [];
    let attachments = [];
    for (const file of e.target.files) {
      files.push(file.name);
      attachments.push(file);
    }
    formik.setFieldValue("address_proof", files);
    setAddressDocs(attachments);
  };

  const handleSameAsAddress = (e) => {
    formik.setFieldValue("sameAsAddressProof", e.target.checked);
    if (e.target.checked) {
      formik.setFieldValue(
        "address_proof",
        formik.values.verification_document
      );
    } else {
      let files = [];
      let attachments = [];
      for (const file of addressDocs) {
        files.push(file.name);
        attachments.push(file);
      }
      formik.setFieldValue("address_proof", files);
      setAddressDocs(attachments);
    }
  };

  useEffect(() => {
    formik.setFieldValue("email", userData?.email);
  }, [userData]);


  // console.log(verificationDocs, 'verificationDocs');
  //console.log(addressDocs, 'addressDocs')
  //console.log(formik.errors)

  return (
    <>
      <div className="mt-4">
        <Form onSubmit={formik.handleSubmit}>
          <Form.Label className="text-dark">
            Upload Verification Documents
          </Form.Label>
          <Form.Group
            as={Col}
            md="12"
            controlId="validationCustom01"
            className="mb-3"
          >
            <div className="btn_upload p-4">
              <Form.Label className="text-dark">
                <div className="mb-2">
                  <FontAwesomeIcon icon={faImage} />
                </div>
                upload Government-issued ID
              </Form.Label>
              <Form.Control
                type="file"
                className="input-img"
                name="verification_document[]"
                accept="image/*"
                onChange={(e) => handleVerification(e)}
                // onChange={formik.handleChange}
                multiple
              />
            </div>
            {
              userData?.verification_document?.length > 0 && userData?.verification_document.map((item) => {
                return (<a key={item?._id} href={`${process.env.REACT_APP_SERVER_URL}/uploads/users/original/${item?.document}`}>{item?.document}</a>)
              })
            }
            <span style={{ color: "red" }}>
              {formik.errors.verification_document ? (
                <div>{formik.errors.verification_document} </div>
              ) : null}{" "}
            </span>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Check
              className="text-muted"
              feedbackType="invalid"
              id="sameAsAddressProof"
              name="sameAsAddressProof"
              label="Same as Proof of Address"
              //onChange={formik.handleChange}
              onChange={(e) => handleSameAsAddress(e)}
              value={formik.values.sameAsAddressProof}
              checked={formik.values.sameAsAddressProof}
            />
          </Form.Group>
          {!formik.values.sameAsAddressProof && (
            <Form.Group
              as={Col}
              md="12"
              controlId="validationCustom01"
              className="mb-3"
            >
              <Form.Label className="text-dark">Proof of address</Form.Label>
              <div className="btn_upload p-4">
                <Form.Control
                  type="file"
                  placeholder="Email Address"
                  className="input-img"
                  name="address_proof[]"
                  accept="image/*"
                  //onChange={formik.handleChange}
                  onChange={(e) => handleAddressProof(e)}
                  multiple
                />
                <div className="mb-2">
                  <FontAwesomeIcon icon={faImage} />
                </div>
                upload Government-issued ID
              </div>
              {
                userData?.address_proof?.length > 0 && userData?.address_proof.map((item) => {
                  return (<a key={item?._id} href={`${process.env.REACT_APP_SERVER_URL}/uploads/users/original/${item?.document}`}>{item?.document}</a>)
                })
              }
              <span style={{ color: "red" }}>
                {formik.errors.address_proof ? (
                  <div>{formik.errors.address_proof} </div>
                ) : null}{" "}
              </span>
            </Form.Group>
          )}

          <Form.Group
            as={Col}
            md="12"
            controlId="validationCustom01"
            className="mb-3"
          >
            <Form.Label className="text-dark">
              Enter Valid email address{" "}
            </Form.Label>
            <div className="position-relative form-email-icon">
              <span className="position-absolute">
                <FontAwesomeIcon icon={faEnvelopeOpen} />
              </span>
              <Form.Control
                className="ps-5"
                type="text"
                placeholder="Email Address"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                disabled
              />
              <span style={{ color: "red" }}>
                {formik.errors.email ? <div>{formik.errors.email} </div> : null}{" "}
              </span>
            </div>
          </Form.Group>
          <Form.Group as={Col} md="12" controlId="validationCustom01">
            <Form.Label className="text-dark">Address Details</Form.Label>
            <Form.Control
              as="textarea"
              type="text"
              placeholder="Enter Address"
              name="address"
              value={formik.values.address}
              onChange={formik.handleChange}
            />
            <span style={{ color: "red" }}>
              {formik.errors.address ? (
                <div>{formik.errors.address} </div>
              ) : null}{" "}
            </span>
          </Form.Group>
          <Button type="submit" className="login-btn px-4 w-100 my-4">
            Save
          </Button>
        </Form>
      </div>
    </>
  );
}

export default StepOne;
