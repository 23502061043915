import React, { useState, useMemo, useEffect } from "react";
import AdminSidebar from "../AdminSidebar";
import DashboardHeader from "../DashboardHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { Accordion, Button, Col, Container, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import AdminNavBar from "../AdminNavBar";
import { getTutorialsThunk } from "../../../features/tutorial/tutorialThunk";
import { tutorialSelector } from "../../../features/tutorial/tutorialSelector";
import { changeTutorialLimit, changeTutorialPage } from "../../../features/tutorial/tutorialSlice";
import { useDispatch, useSelector } from "react-redux";
import { authSelector } from "../../../features/auth/authSelector";
import ReactPaginate from "react-paginate";
import { getFaqsThunk } from "../../../features/faq/faqThunk";
import { faqSelector } from "../../../features/faq/faqSelector";
import { changeLimit, changePage } from "../../../features/faq/faqSlice";

function LearningCenter() {
  const [showContent, setShowContent] = useState(false);
  const dispatch = useDispatch();
  const { tutorials, totalTutorials, tutorialLimit, tutorialPage } = useSelector(tutorialSelector);
  const { faqs, totalFaqs, limit, page } = useSelector(faqSelector);
  const { token, userData } = useSelector(authSelector);

  const [configTutorialObject] = useState({
    headers: {
      Authorization: "Bearer " + token,
    },
    params: {
      limit: tutorialLimit,
      page: tutorialPage,
    },
  });

  const [configObject] = useState({
    headers: {
      Authorization: "Bearer " + token,
    },
    params: {
      limit: limit,
      page: page,
    },
  });

  const pageCount = useMemo(() => {
    const pageCounter = Math.ceil(totalTutorials / tutorialLimit);
    return pageCounter;
  }, [totalTutorials, tutorialLimit]);

  const faqpageCount = useMemo(() => {
    const pageCounter = Math.ceil(totalFaqs / limit);
    return pageCounter;
  }, [totalFaqs, limit]);

  const toggleSidebar = () => setShowContent(!showContent);

  useEffect(() => {
    getTutorials();
    getFaqs();
  }, []);

  const getTutorials = async () => {
    await dispatch(getTutorialsThunk({ ...configTutorialObject }));
  };

  const handlePageClick = async (data) => {
    await dispatch(changeTutorialPage(data.selected + 1));
    await dispatch(
      getTutorialsThunk({
        ...configTutorialObject,
        params: {
          limit: tutorialLimit,
          page: data.selected + 1,
        },
      })
    );
  };

  const getFaqs = async () => {
    await dispatch(getFaqsThunk({ ...configTutorialObject }));
  };

  const handleFaqPageClick = async (data) => {
    await dispatch(changePage(data.selected + 1));
    await dispatch(
      getFaqsThunk({
        ...configObject,
        params: {
          limit: limit,
          page: data.selected + 1,
        },
      })
    );
  };

  return (
    <>
      <div
        className={
          showContent
            ? "dashboard-main content-visible"
            : "dashboard-main"
        }
      >
        {/* <div>
          <AdminSidebar />
        </div> */}
        <div className="dashboard-body">
          <DashboardHeader toggleSidebar={toggleSidebar} />
          <Container>
            {/* <AdminNavBar /> */}
            <div className="dashboard-content p-2 p-md-4 landing-center">
              <h4 className="fw-bold text-center my-3">
                {" "}
                How Can we help you ?{" "}
              </h4>
              <Row className="justify-content-center">
                <Col md={8} className="mb-4">
                  <Form className="masterSearch position-relative mb-5">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Control
                        type="search"
                        placeholder="name@example.com"
                        className="pe-5 border-0"
                      />
                    </Form.Group>
                    <Button className="rounded-circle align-items-center justify-content-center">
                      <FontAwesomeIcon icon={faMagnifyingGlass} />
                    </Button>
                  </Form>
                  <div className="text-center">
                    <h5 className="fw-bold">How to Start Investing</h5>
                    <p className="text-muted">
                      Select an option to learn about Crowdfina Investment
                    </p>
                  </div>
                </Col>
              </Row>
              <Row>
                {
                  totalTutorials > 0 && tutorials.map((item) => {
                    return (<Col lg={3} md={6} className="mb-4" key={item?._id}>
                      <div className="investPropertycard p-3" >
                        <video className="property-img img-fluid mb-4" src={`${process.env.REACT_APP_SERVER_URL}/uploads/tutorials/${item?.video}`} controls />
                        {/* <img
                          className="property-img img-fluid mb-4"
                          src={require("../../../images/video-cta-img.png")}
                          alt=""
                        /> */}
                        <h4 className="propertyHeading">{item?.title}</h4>
                        <p className="expected_rate text-muted mb-0">
                          {" "}
                          By {item?.user?.full_name}
                        </p>
                      </div>
                    </Col>)
                  })
                }
              </Row>
              <div className="text-center py-4">
                <span className="dashboard_badge success">FAQ</span>
              </div>
              <h2 className="fw-bold text-center mb-4">Have Any Question?</h2>
              <Row className="justify-content-center">
                <Col lg={6} md={7}>
                  <Accordion className="faq_accordians">
                    {
                      totalFaqs > 0 && faqs.map((faq) => {
                        return (<Accordion.Item eventKey={faq?._id} key={faq?._id}>
                          <Accordion.Header>
                            {faq?.question}
                          </Accordion.Header>
                          <Accordion.Body className="text-muted">
                            {faq?.answer}
                          </Accordion.Body>
                        </Accordion.Item>)
                      })
                    }
                  </Accordion>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
}

export default LearningCenter;
