import React, { useState, useEffect, useMemo } from "react";
import AdminSidebar from "../AdminSidebar";
import DashboardHeader from "../DashboardHeader";
import { Col, Container, Row, Table } from "react-bootstrap";
import { CircularProgressbar } from "react-circular-progressbar";
import AdminNavBar from "../AdminNavBar";
import { getMyInvestedPropertiesThunk } from "../../../features/property/propertyThunk";
import { propertySelector } from "../../../features/property/propertySelector";
import { changeInvestLimit, changeInvestPage } from "../../../features/property/propertySlice";
import { useDispatch, useSelector } from "react-redux";
import { authSelector } from "../../../features/auth/authSelector";
import { Chart } from "react-google-charts";
import { getPortfolioStatsThunk } from "../../../features/investment/investmentThunk";
import ReactPaginate from "react-paginate";
import { getProfileThunk } from "../../../features/auth/authThunk";

const percentage = 66;

function AdminPortfolio() {
  const dispatch = useDispatch();
  const [showContent, setShowContent] = useState(false);
  const { myInvestProperties, investTotal, investLimit, investPage } = useSelector(propertySelector);
  const { token, userData } = useSelector(authSelector);
  const [overviewData, setOverviewData] = useState([["Month", "Invest", "Sold"]]);
  const [investedAmount, setInvestedAmount] = useState(0);
  const [soldAmount, setSoldAmount] = useState(0);
  const [profitAvg, setProfitAvg] = useState(0);

  const [configObject] = useState({
    headers: {
      Authorization: "Bearer " + token,
    },
    params: {
      limit: investLimit,
      page: 1,
    },
  });

  const pageCount = useMemo(() => {
    const pageCounter = Math.ceil(investTotal / investLimit);
    return pageCounter;
  }, [investTotal, investLimit]);

  useEffect(() => {
    getProperties();
    getPortfolioStats();
    getData();
  }, []);

  const getProperties = async () => {
    await dispatch(getMyInvestedPropertiesThunk({ ...configObject }));
  }

  const getData = async () => {
    await dispatch(getProfileThunk({ ...configObject }));
  }

  const getPortfolioStats = async () => {
    const resp = await dispatch(getPortfolioStatsThunk({ ...configObject }));
    if (resp?.payload?.status === 200) {
      const overview = resp?.payload?.data?.body?.overview;
      const investedAmount = resp?.payload?.data?.body?.investedAmount;
      const soldAmount = resp?.payload?.data?.body?.soldAmount;
      setInvestedAmount(investedAmount);
      setSoldAmount(soldAmount);

      const profitavg = ((soldAmount - investedAmount) / investedAmount) * 100;
      setProfitAvg(profitavg > 0 ? profitavg : 0);

      let dataArr = [["Month", "Invest", "Sold"]];
      const investOvw = overview.length > 0 && overview.map((o) => {
        dataArr.push([o?.period, o?.depositTotal, o?.withdrawTotal]);
      });
      setOverviewData(dataArr);
    }
    //console.log(statsData)
  }

  const options = {
    title: "Overview",
    curveType: "function",
    legend: { position: "bottom" },
    tooltip: { isHtml: true, trigger: 'both' },
  };

  const handlePageClick = async (data) => {
    await dispatch(changeInvestPage(data.selected + 1));
    await dispatch(
      getMyInvestedPropertiesThunk({
        ...configObject,
        params: {
          limit: investLimit,
          page: data.selected + 1,
        },
      })
    );
  };

  const toggleSidebar = () => setShowContent(!showContent);
  return (
    <>
      <div className={showContent ? 'dashboard-main content-visible' : 'dashboard-main'}>
        {/* <div>
          <AdminSidebar />
        </div> */}
        <div className="dashboard-body">
          <DashboardHeader toggleSidebar={toggleSidebar} />
          <Container>
            <AdminNavBar />
            <div className="dashboard-content p-2 p-md-4">
              <Row>
                <Col md={4} className="mb-4">
                  <div className="dashboardCard p-4 h-100">
                    <div className="d-flex align-items-center justify-content-between gap-2 flex-wrap">
                      <div className="card-headings">
                        <p className="mb-2">Gain Amount</p>
                        <h3>${soldAmount > investedAmount ? soldAmount - investedAmount : 0}</h3>
                      </div>
                      <div style={{ width: 100, height: 100 }}>
                        <CircularProgressbar
                          value={soldAmount > investedAmount ? soldAmount - investedAmount : 0}
                          text={`${soldAmount > investedAmount ? soldAmount - investedAmount : 0}`}
                        />
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={4} className="mb-4">
                  <div className="dashboardCard p-4 h-100">
                    <div className="d-flex align-items-center justify-content-between gap-2 flex-wrap">
                      <div className="card-headings">
                        <p className="mb-2">Invest Amount</p>
                        <h3>${investedAmount}</h3>
                      </div>
                      <div className="card-headings">
                        <p className="mb-2">Sold Amount</p>
                        <h3>
                          ${soldAmount}&nbsp;

                        </h3>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={4} className="mb-4">
                  <div className="dashboardCard p-4 h-100">
                    <div className="d-flex align-items-center justify-content-between gap-2 flex-wrap">
                      <div className="card-headings">
                        <p className="mb-2">Gain Percentage</p>
                        <h3>{profitAvg.toFixed(2)}%</h3>
                      </div>
                      <div style={{ width: 100, height: 100 }}>
                        <CircularProgressbar
                          value={profitAvg.toFixed(2)}
                          text={`${profitAvg.toFixed(2)}%`}
                        />
                      </div>
                    </div>
                  </div>
                </Col>

                <Col md={6} className="mb-4">
                  <div className="dashboardCard p-4 h-100">
                    <div className="d-flex align-items-center justify-content-between">
                      <h4 className="Cardheading">Property Referrals</h4>
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                        >
                          <path
                            d="M8.25 9C8.25 9.41421 8.58579 9.75 9 9.75C9.41421 9.75 9.75 9.41421 9.75 9C9.75 8.58579 9.41421 8.25 9 8.25C8.58579 8.25 8.25 8.58579 8.25 9Z"
                            stroke="#808191"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M8.25 14.25C8.25 14.6642 8.58579 15 9 15C9.41421 15 9.75 14.6642 9.75 14.25C9.75 13.8358 9.41421 13.5 9 13.5C8.58579 13.5 8.25 13.8358 8.25 14.25Z"
                            stroke="#808191"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M8.25 3.75C8.25 4.16421 8.58579 4.5 9 4.5C9.41421 4.5 9.75 4.16421 9.75 3.75C9.75 3.33579 9.41421 3 9 3C8.58579 3 8.25 3.33579 8.25 3.75Z"
                            stroke="#808191"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                    <img
                      src={require("../../../images/property_graph.png")}
                      alt="propertygraph"
                      className="w-100"
                    />
                  </div>
                </Col>

                <Col md={6} className="mb-4">
                  <div className="dashboardCard p-4 h-100">
                    {/* <div className="d-flex align-items-center justify-content-between">
                      <h4 className="Cardheading">Overview</h4>
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                        >
                          <path
                            d="M8.25 9C8.25 9.41421 8.58579 9.75 9 9.75C9.41421 9.75 9.75 9.41421 9.75 9C9.75 8.58579 9.41421 8.25 9 8.25C8.58579 8.25 8.25 8.58579 8.25 9Z"
                            stroke="#808191"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M8.25 14.25C8.25 14.6642 8.58579 15 9 15C9.41421 15 9.75 14.6642 9.75 14.25C9.75 13.8358 9.41421 13.5 9 13.5C8.58579 13.5 8.25 13.8358 8.25 14.25Z"
                            stroke="#808191"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M8.25 3.75C8.25 4.16421 8.58579 4.5 9 4.5C9.41421 4.5 9.75 4.16421 9.75 3.75C9.75 3.33579 9.41421 3 9 3C8.58579 3 8.25 3.33579 8.25 3.75Z"
                            stroke="#808191"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                    <img
                      src={require("../../../images/overview_graph.png")}
                      alt="propertygraph"
                      className="w-100"
                    /> */}
                    {
                      overviewData.length > 1 ? <Chart
                        chartType="LineChart"
                        width="100%"
                        height="400px"
                        data={overviewData}
                        options={options}
                      />
                        : <p className="text-center">No chart data available</p>
                    }

                  </div>
                </Col>
              </Row>

              <div className="table-responsive theme-table">
                <h5 className="Cardheading mx-3">Property List</h5>
                <Table>
                  <thead>
                    <tr>
                      <th>Admin by</th>
                      <th>Property Type</th>
                      <th>Location</th>
                      <th>Total Investors</th>
                      <th>Total Price</th>
                      <th>Funding</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      investTotal > 0 ? myInvestProperties.map((item) => {
                        return (
                          <tr key={item?._id}>
                            <td>
                              <div className="d-flex align-items-center gap-3">

                                {
                                  item?.user?.profile_image ? <img
                                    src={`${process.env.REACT_APP_SERVER_URL}/uploads/users/original/${item?.user?.profile_image}`}
                                    alt=""
                                    height={40}
                                    width={40}
                                    className="rounded-circle"
                                  /> : <img
                                    src={require("../../../images/testimonial-user.png")}
                                    alt=""
                                    height={40}
                                    width={40}
                                    className="rounded-circle"
                                  />
                                }

                                <span>{item?.user?.full_name}</span>

                              </div>
                            </td>
                            <td>{item?.category?.title}</td>
                            <td>{item?.address} {item?.location}, {item?.city}, {item?.country}, {item?.postal_code}</td>
                            <td>{item?.totalInvestors}</td>
                            <td>${item?.property_value}</td>
                            <td>
                              <span className={`dashboard_badge text-capitalize  ${item?.funding_status === 'fully_funded' ? 'success' : 'warning'}`}>{item?.funding_status.replaceAll("_", " ")}</span>
                            </td>
                          </tr>
                        )
                      })
                        : <tr><td colSpan={6} className="text-center">No record found</td></tr>
                    }
                  </tbody>
                </Table>
              </div>

              <div className="mt-4">
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  breakLabel={"..."}
                  pageCount={pageCount === 1 ? 1 : pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={3}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination justify-content-center"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"pagination_prev"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"pagination_next"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                />
              </div>

            </div>

          </Container>
        </div>
      </div>
    </>
  );
}

export default AdminPortfolio;
