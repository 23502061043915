import { Button, Col, Form } from "react-bootstrap";
import React, { useState } from "react";
import { useFormik } from "formik";
import { investProfileStep2Schema } from "../../utils/validationsSchema";
import { updateDetailsThunk } from "../../features/auth/authThunk";
import { authSelector } from "../../features/auth/authSelector";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import Select from "react-select";
import { useNavigate } from "react-router-dom";

function StepTwo(props) {
  const { userData, token } = useSelector(authSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [configObject, setConfigObject] = useState({
    headers: {
      Authorization: "Bearer " + token,
    },
  });

  const formik = useFormik({
    initialValues: {
      investment_objectives: userData?.investment_objectives ?? [],
      investment_horizon: userData?.investment_horizon ?? "",
      sustainability: userData?.sustainability ?? "",
      ability_to_bear_losses: userData?.ability_to_bear_losses ?? "",
      investment_experience: userData?.investment_experience ?? "",
      professional_status: userData?.professional_status ?? "",
      annual_income_range: userData?.annual_income_range ?? "",
      estimated_total_wealth: userData?.estimated_total_wealth ?? "",
      income_currency: userData?.income_currency ?? "",
      income_currency: "$",
    },
    validationSchema: investProfileStep2Schema,
    onSubmit: async (values) => {
      console.log(values)
      try {
        const resp2 = await dispatch(
          updateDetailsThunk({ ...configObject, body: values })
        );
        if (resp2?.payload?.status === 200) {
          toast.success("User details are saved successfully");
          navigate("/investors");
        } else {
          toast.error("Some problem exist, Please try again");
        }
      } catch (error) {
        toast.error("Some problem exist, Please try again");
      }
    },
  });

  const objectiveOptions = [
    {
      value: "obtain_additional_income",
      label: "Obtain additional Income",
    },
    {
      value: "diversify_my_investment",
      label: "Diversify My investment",
    },
    {
      value: "increase_the_value_of_my_capital",
      label: "Increase the value of my Capital",
    },
    {
      value: "improve_the_profitability_on_my_investments",
      label: "Improve the profitability on my investments",
    },
  ];

  console.log(formik.errors);

  return (
    <>
      <div className="mt-4">
        <form onSubmit={formik.handleSubmit}>
          <Form.Group
            as={Col}
            md="12"
            controlId="validationCustom01"
            className="mb-3"
          >
            <Form.Label className="text-dark">
              Your Investment Objectives
            </Form.Label>
            <Select
              isMulti
              onChange={(option) => {
                formik.setFieldValue(
                  "investment_objectives",
                  option !== null ? option.map((item) => item.value) : []
                );
              }}
              value={objectiveOptions.filter((option) => formik.values.investment_objectives.indexOf(option.value) >= 0)}
              // value={objectiveOptions.filter(
              //   (option) =>
              //     formik.values.investment_objectives.indexOf(option.value) >= 0
              // )}
              name="investment_objectives"
              options={objectiveOptions}
              placeholder="Select "
              className="basic-multi-select"
              classNamePrefix="select"
            />
            {/* <Form.Select aria-label="Default select example" multiple name="investment_objectives" onChange={formik.handleChange}>
              <option value="">Select</option>
              <option value="obtain_additional_income">Obtain additional Income</option>
              <option value="diversify_my_investment">Diversify My investment</option>
              <option value="increase_the_value_of_my_capital">Increase the value of my Capital</option>
              <option value="improve_the_profitability_on_my_investments">Improve the profitability on my investments</option>
            </Form.Select> */}
            <span style={{ color: "red" }}>
              {formik.errors.investment_objectives ? (
                <div>{formik.errors.investment_objectives} </div>
              ) : null}{" "}
            </span>
          </Form.Group>
          <Form.Group
            as={Col}
            md="12"
            controlId="validationCustom01"
            className="mb-3"
          >
            <Form.Label className="text-dark">
              Your Investment Horizon
            </Form.Label>
            <Form.Select
              aria-label="Default select example"
              name="investment_horizon"
              onChange={formik.handleChange}
              value={formik.values.investment_horizon}
            >
              <option value="">Select</option>
              <option value="short_term">Short Term (Less than 2 years)</option>
              <option value="medium_term">Medium Term (2 to 5 years)</option>
              <option value="long_term">Long Term (Over 5 years)</option>
            </Form.Select>
            <span style={{ color: "red" }}>
              {formik.errors.investment_horizon ? (
                <div>{formik.errors.investment_horizon} </div>
              ) : null}{" "}
            </span>
          </Form.Group>
          <Form.Group
            as={Col}
            md="12"
            controlId="validationCustom01"
            className="mb-3"
          >
            <Form.Label className="text-dark">sustainability</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Sustainability"
              onChange={formik.handleChange}
              name="sustainability"
              value={formik.values.sustainability}
            />
            <span style={{ color: "red" }}>
              {formik.errors.sustainability ? (
                <div>{formik.errors.sustainability} </div>
              ) : null}{" "}
            </span>
          </Form.Group>
          <Form.Group
            as={Col}
            md="12"
            controlId="validationCustom01"
            className="mb-3"
          >
            <Form.Label className="text-dark">
              Ability to Bear Losses
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Ability to Bear Losses"
              onChange={formik.handleChange}
              name="ability_to_bear_losses"
              value={formik.values.ability_to_bear_losses}
            />
            <span style={{ color: "red" }}>
              {formik.errors.ability_to_bear_losses ? (
                <div>{formik.errors.ability_to_bear_losses} </div>
              ) : null}{" "}
            </span>
          </Form.Group>
          <Form.Group
            as={Col}
            md="12"
            controlId="validationCustom01"
            className="mb-3"
          >
            <Form.Label className="text-dark">Investment Experience</Form.Label>
            <Form.Control
              type="text"
              placeholder="5 years"
              onChange={formik.handleChange}
              name="investment_experience"
              value={formik.values.investment_experience}
            />
            <span style={{ color: "red" }}>
              {formik.errors.investment_experience ? (
                <div>{formik.errors.investment_experience} </div>
              ) : null}{" "}
            </span>
          </Form.Group>
          <Form.Group
            as={Col}
            md="12"
            controlId="validationCustom01"
            className="mb-3"
          >
            <Form.Label className="text-dark">
              Professional Situation
            </Form.Label>
            <Form.Select
              aria-label="Default select example"
              name="professional_status"
              onChange={formik.handleChange}
              value={formik.values.professional_status}
            >
              <option value="">Select</option>
              <option value="in_activity">In activity </option>
              <option value="retired">Retired </option>
              <option value="student">Student </option>
              <option value="without_professional_activity">
                Without Professional Activity{" "}
              </option>
            </Form.Select>
            <span style={{ color: "red" }}>
              {formik.errors.professional_status ? (
                <div>{formik.errors.professional_status} </div>
              ) : null}{" "}
            </span>
          </Form.Group>
          <Form.Group
            as={Col}
            md="12"
            controlId="validationCustom01"
            className="mb-3"
          >
            <Form.Label className="text-dark">Annual Income</Form.Label>
            <Form.Select
              aria-label="Default select example"
              name="annual_income_range"
              onChange={formik.handleChange}
              value={formik.values.annual_income_range}
            >
              <option value="">Select</option>
              <option value="below_20000">Below 20000$ or €</option>
              <option value="between_20000_and_50000">
                Between 20000 and 50000
              </option>
              <option value="between_50000_and_75000">
                Between 50000 and 75000
              </option>
              <option value="between_75000_and_100000">
                Between 75000 and 100000
              </option>
              <option value="over_100000">Over 100000</option>
            </Form.Select>
            <span style={{ color: "red" }}>
              {formik.errors.annual_income_range ? (
                <div>{formik.errors.annual_income_range} </div>
              ) : null}{" "}
            </span>
          </Form.Group>
          <Form.Group
            as={Col}
            md="12"
            controlId="validationCustom01"
            className="mb-3"
          >
            <Form.Label className="text-dark">Total Wealth</Form.Label>
            <Form.Select
              aria-label="Default select example"
              name="estimated_total_wealth"
              onChange={formik.handleChange}
              value={formik.values.estimated_total_wealth}
            >
              <option value="">Select</option>
              <option value="below_50000">Below 50000$ or €</option>
              <option value="between_50000_and_100000">
                Between 50000 and 100000
              </option>
              <option value="between_100000_and_200000">
                Between 100000 and 200000
              </option>
              <option value="between_200000_and_400000">
                Between 200000 and 400000
              </option>
              <option value="over_400000">Over 400000</option>
            </Form.Select>
            <span style={{ color: "red" }}>
              {formik.errors.estimated_total_wealth ? (
                <div>{formik.errors.estimated_total_wealth} </div>
              ) : null}{" "}
            </span>
          </Form.Group>

          <Button className="login-btn px-4 w-100 my-4" type="submit">
            Submit
          </Button>
        </form>
      </div>
    </>
  );
}

export default StepTwo;
