import React, { useState, useEffect } from "react";
import AdminSidebar from "../AdminSidebar";
import DashboardHeader from "../DashboardHeader";
import {
  Accordion,
  Button,
  Col,
  Container,
  Form,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBath,
  faBed,
  faCircleInfo,
  faHouse,
  faLocationPin,
  faRuler,
} from "@fortawesome/free-solid-svg-icons";
import AdminNavBar from "../AdminNavBar";
import ImageGallery from "react-image-gallery";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { addTransactionThunk, getTransactionStatsThunk } from "../../../features/transaction/transactionThunk";
import { useDispatch, useSelector } from "react-redux";
import { authSelector } from "../../../features/auth/authSelector";
import { toast } from "react-hot-toast";
import { transactionSelector } from "../../../features/transaction/transactionSelector";
import { addInvestmentThunk } from "../../../features/investment/investmentThunk";
import { getProfileThunk } from "../../../features/auth/authThunk";

function PropertyDetail() {
  const [showContent, setShowContent] = useState(false);
  const dispatch = useDispatch();
  const toggleSidebar = () => setShowContent(!showContent);
  const location = useLocation();
  const property = location?.state?.property;
  const { token, userData } = useSelector(authSelector);
  const { walletBalance } = useSelector(transactionSelector);
  const distributedSum = property?.distributed_revenues_amount * 12;
  const minShareAvg = ((property?.minimum_investment_amount / property?.property_value) * 100).toFixed(2);
  const cashflow = ((minShareAvg / 100) * distributedSum).toFixed(2);
  const [durationProfit, setDurationProfit] = useState((cashflow * property?.investment_duration));
  const [durationProfitAvg, setDurationProfitAvg] = useState(((cashflow * property?.investment_duration) / property?.minimum_investment_amount) * 100);
  const [monthlyProfit, setMonthlyProfit] = useState((cashflow * (1 / 12)));
  const [monthlyProfitAvg, setMonthlyProfitAvg] = useState(((cashflow * (1 / 12)) / property?.minimum_investment_amount) * 100);

  const [configObject] = useState({
    headers: {
      Authorization: `Bearer ${token}`,
    }
  });

  const images = property?.property_images.length > 0 && property?.property_images.map((item) => {
    let image = {
      original: process.env.REACT_APP_SERVER_URL + "/uploads/properties/original/" + item?.image,
      thumbnail: process.env.REACT_APP_SERVER_URL + "/uploads/properties/thumbnail/" + item?.image,
    }
    return image;
  })

  const [rangeValue, setRangeValue] = useState(property?.minimum_investment_amount ?? 0); // Initial value is set to 50, you can set it to your preferred initial value

  const handleRangeChange = (e) => {
    const value = e.target.value;
    setRangeValue(value);

    const minShareAvg = ((value / property?.property_value) * 100).toFixed(2);
    const cashflow = ((minShareAvg / 100) * distributedSum).toFixed(2);
    setDurationProfit((cashflow * property?.investment_duration));
    //setDurationProfitAvg(((cashflow * property?.investment_duration) / value) * 100);
    setMonthlyProfit((cashflow * (1 / 12)));
    ///setMonthlyProfitAvg(((cashflow * (1 / 12)) / value) * 100);

  };

  const addMoney = async () => {
    const response = await dispatch(addTransactionThunk({
      ...configObject, body: {
        type: 'deposit',
        via: 'payment',
        status: 'completed',
        amount: rangeValue,
      }
    }));

    if (response?.payload?.status === 200) {
      toast.success('Money added successfully');
      await dispatch(getTransactionStatsThunk({ ...configObject }));
    } else {
      toast.error('Some problem exist. Please try again.');
    }
  }

  const investMoney = async () => {
    const userResp = await dispatch(getProfileThunk({ ...configObject }));
    if (userResp?.payload?.status == 200 && userResp?.payload?.data?.body?.documentVerified) {
      const resp = await dispatch(addInvestmentThunk({
        ...configObject, body: {
          type: 'buy',
          propertyId: property?._id,
          status: 'completed',
          amount: rangeValue,
          tokenQuantity: rangeValue / 10,
          tokenAmount: 10
        }
      }));

      if (resp?.payload?.status === 200) {
        const response = await dispatch(addTransactionThunk({
          ...configObject, body: {
            type: 'withdraw',
            via: 'investment',
            status: 'completed',
            amount: rangeValue,
            propertyId: resp?.payload?.data?.body?._id
          }
        }));

        toast.success('Money invested successfully')

      } else {
        toast.error('Some problem exist. Please try again.');
      }
    } else {
      toast.error('Your document verification is inprogress. After verified you can invest');
    }
  }

  const sliderValue = () => {
    const value = (100 * rangeValue) / property?.property_value;
    let dd = 5;
    if (value > 50) {
      dd = 15;
    } else if (value > 10 && value <= 50) {
      dd = 10;
    } else {
      dd = 5;
    }
    return dd;
  }

  useEffect(() => {
    getData();
  })

  const getData = async () => {
    await dispatch(getTransactionStatsThunk({ ...configObject }))
  }

  return (
    <>
      <div
        className={
          showContent ? "dashboard-main content-visible" : "dashboard-main"
        }
      >
        {/* <div>
          <AdminSidebar />
        </div> */}
        <div className="dashboard-body">
          <DashboardHeader toggleSidebar={toggleSidebar} />
          <Container>
            <AdminNavBar />
            <div className="dashboard-content p-2 p-md-4">
              <div className="main-content p-2 p-lg-4 mb-4">
                <div className="single-property-card">
                  <Row
                  // className="align-items-center"
                  >
                    <Col lg={7} className="mb-4">
                      <div className=" mb-4">
                        {/* <img
                          src={require("../../../images/landing-banner.png")}
                          alt=""
                          className="object-fit-cover"
                        /> */}
                        <ImageGallery items={images} />
                      </div>
                      <div className="d-flex justify-content-between align-items-center flex-wrap">
                        <h2 className="singlepropertyHeading">
                          {property?.name}
                        </h2>
                        <span className={`dashboard_badge d-inline-block mb-3 text-capitalize ${property?.funding_status === 'fully_funded' ? 'success' : 'warning'}`}>
                          {property?.funding_status.replaceAll("_", " ")}
                        </span>
                      </div>
                      <div className="d-flex align-items-center gap-3 pb-4 border-bottom mb-4">
                        {
                          property?.rooms ?
                            <>
                              <span>
                                <FontAwesomeIcon icon={faBed} /> {property?.rooms}
                              </span>
                              <span>|</span>
                            </> : ''
                        }
                        {
                          property?.bathrooms ?
                            <>
                              <span>
                                <FontAwesomeIcon icon={faBath} /> {property?.bathrooms}
                              </span>
                              <span>|</span>
                            </> : ""
                        }
                        {
                          property?.area ?
                            <>
                              <span>
                                <FontAwesomeIcon icon={faRuler} /> {property?.area} sqrt.
                              </span>
                            </> : ""
                        }
                        {/* <span>|</span>
                        <span>
                          <FontAwesomeIcon icon={faHouse} /> 5
                        </span>
                        <span>|</span> */}
                      </div>
                      <Tabs
                        defaultActiveKey="Details"
                        id="uncontrolled-tab-example"
                        className="propertyDetails-tabs mb-3 border-0"
                      >
                        <Tab eventKey="Details" title="Details">
                          <div className="propertyDetails_tab_card mb-4">
                            <Row>
                              <Col>
                                <div className="price_details w-100 border-end">
                                  <p className="mb-0">US${property?.property_value}</p>
                                  <Link>
                                    Purchase Price{" "}
                                    <FontAwesomeIcon icon={faCircleInfo} />
                                  </Link>
                                </div>
                              </Col>
                              <Col>
                                <div className="price_details w-100 border-end">
                                  <p className="mb-0">US${property?.anticipated_monthly_rent}</p>
                                  <Link>
                                    Rent/Mo{" "}
                                    <FontAwesomeIcon icon={faCircleInfo} />
                                  </Link>
                                </div>
                              </Col>
                              <Col>
                                <div className="price_details w-100 ">
                                  <p className="mb-0">0%</p>
                                  <Link>
                                    Purchase Leverage{" "}
                                    <FontAwesomeIcon icon={faCircleInfo} />
                                  </Link>
                                </div>
                              </Col>
                            </Row>
                          </div>
                          <div className="propertyDetails_tab_card mb-4">
                            <h5 className="fw-bold"> About </h5>
                            <p className="property-discription">
                              {property?.description}
                            </p>
                          </div>

                          <div className="propertyDetails_tab_card mb-4">
                            <h5 className="fw-bold">
                              {" "}
                              Don't Know Which Property to Choose?{" "}
                            </h5>

                            <p className="fw-semibold">
                              We make it easy with funds
                            </p>
                            <p className="property-discription">
                              For those looking to build or add to their
                              portfolio without investing in individual rental
                              properties, the CrowdFina Single Family
                              Residential Fund is an ideal option due to its
                              always-available investment experience, immediate
                              diversification, and access to liquidity.
                            </p>
                            <div className="propertyDetails_tab_card mb-4">
                              <Link className="propertydetiailink">
                                <div className="d-flex gap-3">
                                  <div>
                                    <img
                                      src={require("../../../images/gallary1.png")}
                                      alt="image"
                                      height={80}
                                      width={80}
                                      className="rounded"
                                    />
                                  </div>
                                  <div>
                                    <h6 className="mb-0 text-dark fw-bold">
                                      Single Family Residential
                                    </h6>
                                    <small className="text-muted">
                                      Single Family Residential Fund
                                    </small>
                                    <div className="d-flex gap-3">
                                      <p className="text-muted">
                                        <span className="fw-semibold text-dark">
                                          12
                                        </span>{" "}
                                        Markets
                                      </p>
                                      <p className="text-muted">
                                        <span className="fw-semibold text-dark">
                                          16
                                        </span>{" "}
                                        Markets
                                      </p>
                                      <p className="text-muted">
                                        <span className="fw-semibold text-dark">
                                          6178
                                        </span>{" "}
                                        Investors
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            </div>
                            <div className="propertyDetails_tab_card mb-4">
                              <h5 className="fw-bold mb-4">
                                {" "}
                                Common Questions{" "}
                              </h5>
                              <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey="0">
                                  <Accordion.Header>
                                    How CrowdFina works.
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    CrowdFina acquires rental properties into an
                                    LLC and sells shares in that LLC to the
                                    general public. CrowdFina then manages the
                                    day to day operations including finding
                                    tenants and completing repairs. Investors
                                    receive cash dividends from rental income
                                    each quarter and capture any property value
                                    appreciation.
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                  <Accordion.Header>
                                    What returens can i expect.
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    Investing in Arrived rental properties can
                                    deliver returns to investors in two
                                    different ways
                                  </Accordion.Body>
                                </Accordion.Item>
                              </Accordion>
                            </div>
                          </div>
                        </Tab>
                        {/* <Tab eventKey="Financials" title="Financials">
                          {" "}
                          Financials{" "}
                        </Tab> */}
                      </Tabs>
                    </Col>
                    <Col lg={5} className="mb-4">
                      {/* <Row>
                        <Col sm={6} className="mb-4">
                          <h4 className="propertydetail-headings mb-3">
                            Single Family Homes
                          </h4>
                          <span className="dashboard_badge success d-inline-block mb-3">
                            Fully Funded
                          </span>
                          <p className="address mb-3">
                            <span className="me-2">
                              <FontAwesomeIcon icon={faLocationPin} />
                            </span>
                            Terry Lane, Golden CO 80403
                          </p>
                          <h4 className="propertydetail-headings mb-3">
                            Property Admin
                          </h4>
                          <div className="d-flex align-items-center mb-4">
                            <div className="me-3">
                              <img
                                src={require("../../../images/testimonial-user.png")}
                                alt="investor"
                                width={33}
                                height={33}
                                className="rounded-circle"
                              />
                            </div>
                            <p className="text-muted mb-0">Dominic Boy</p>
                          </div>
                          <h4 className="propertydetail-headings mb-3">
                            Property Amount
                          </h4>
                          <span className="property-detail-num d-block mb-3">
                            {" "}
                            $8456.23{" "}
                          </span>
                        </Col>
                        <Col sm={6} className="mb-4">
                          <h4 className="propertydetail-headings mb-3">
                            Single Family Homes
                          </h4>
                          <span className="property-detail-num d-block mb-3">
                            {" "}
                            $500{" "}
                          </span>
                          <h4 className="propertydetail-headings mb-3">
                            Total Investor
                          </h4>
                          <span className="property-detail-num d-block mb-3">
                            {" "}
                            7{" "}
                          </span>
                        </Col>
                      </Row> */}
                      <div className="filter-card propertyDetails_tab_card">
                        <h6 className="fw-bold">Calculator: ${rangeValue} invested</h6>


                        <div className="growBusiness_progress position-relative mb-3">
                          <Form.Range
                            value={rangeValue}
                            onChange={handleRangeChange}
                            min={property?.minimum_investment_amount ?? 0}
                            max={property?.property_value}
                            step={10}
                          />
                          <span
                            className="filledProgress"
                            style={{ width: `${(100 * rangeValue) / property?.property_value}%` }}
                          ></span>
                          <span
                            className="controller"
                            style={{ left: `calc(${(100 * rangeValue) / property?.property_value}% - ${sliderValue()}px)` }}
                          ></span>
                        </div>

                        <p className="mb-2">
                          Expected cash flow over Investment Horizon - {property.investment_duration} years{" "}
                          <Link className="text-muted">
                            <FontAwesomeIcon icon={faCircleInfo} />
                          </Link>
                        </p>

                        <div className="profavility_card mb-4">
                          <div className="card-header position-relative">
                            <h5 className="mb-0">+${(durationProfit).toFixed(2)}</h5>
                            <span className="profit-percentage">+{durationProfitAvg.toFixed(2)}%</span>
                          </div>

                          <div className="p-3">
                            <p className="text-center fw-semibold text-muted mb-0">
                              ${(durationProfit).toFixed(2)} donated + $0 bonus
                            </p>
                          </div>
                        </div>
                        <p>
                          Monthly Fixed profitability{" "}
                          <Link className="text-muted">
                            <FontAwesomeIcon icon={faCircleInfo} />
                          </Link>
                        </p>
                        <div className="profavility_card mb-4">
                          <div className="card-header position-relative">
                            <h5 className="mb-0">+${monthlyProfit.toFixed(2)}</h5>
                            <span className="profit-percentage">+{monthlyProfitAvg.toFixed(2)}%</span>
                          </div>

                          <div className="p-3">
                            <p className="text-center fw-semibold text-muted mb-0">
                              ${monthlyProfit.toFixed(2)} donated + $0 bonus
                            </p>
                          </div>
                        </div>
                        {
                          walletBalance >= rangeValue
                            ? (
                              property?.funding_status === 'funding_in_progress'
                                ? <Button className="w-100" onClick={investMoney}>Invest Money</Button>
                                : <Button className="w-100 text-capitalize">{property?.funding_status.replaceAll("_", " ")}</Button>
                            )
                            : <Button className="w-100" onClick={addMoney}>Add Money To Wallet</Button>
                        }
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
              {/* <div className="main-content p-2 p-lg-4">
                <Row className="align-items-end">
                  <Col md={6} lg={3} className="mb-4 mb-lg-0">
                    <Form>
                      <Form.Group controlId="exampleForm.ControlInput1">
                        <Form.Label>Enter Amount</Form.Label>
                        <Form.Control type="email" placeholder="$457.23" />
                      </Form.Group>
                    </Form>
                  </Col>
                  <Col md={6} lg={3} className="mb-4 mb-lg-0">
                    <Form>
                      <Form.Group controlId="exampleForm.ControlInput1">
                        <Form.Label>Select Investment</Form.Label>
                        <Form.Select aria-label="Default select example">
                          <option> </option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </Form.Select>
                      </Form.Group>
                    </Form>
                  </Col>
                  <Col md={6} lg={3} className="mb-4 mb-lg-0">
                    <Form>
                      <Form.Group controlId="exampleForm.ControlInput1">
                        <Form.Label>Investment Horizon</Form.Label>
                        <Form.Select aria-label="Default select example">
                          <option> </option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </Form.Select>
                      </Form.Group>
                    </Form>
                  </Col>
                  <Col md={6} lg={3} className="mb-4 mb-lg-0">
                    <Button className="w-100 py-2">Calculate</Button>
                  </Col>
                </Row>
              </div> */}
            </div>
          </Container>
        </div >
      </div >
    </>
  );
}

export default PropertyDetail;
