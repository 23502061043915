import React, { useState, useEffect } from "react";
import Header from "../common/header";
import Footer from "../common/footer";
import {
  Breadcrumb,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Offcanvas,
  ProgressBar,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { getPropertiesThunk } from "../../features/property/propertyThunk";
import { propertySelector } from "../../features/property/propertySelector";
import { changeLimit, changePage } from "../../features/property/propertySlice";
import { useDispatch, useSelector } from "react-redux";
import { authSelector } from "../../features/auth/authSelector";
import { getCategoriesThunk } from "../../features/category/categoryThunk";
import { categorySelector } from "../../features/category/categorySelector";
import { useFormik } from "formik";
import ReactFlagsSelect from "react-flags-select";

import { Link, useNavigate } from "react-router-dom"


function Invest() {
  const [show, setShow] = useState(false);
  const [selectedTab, setSelectedTab] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [comingsoon, setComingsoon] = useState(true);
  const [rangeValue, setRangeValue] = useState(50); // Initial value is set to 50, you can set it to your preferred initial value

  // Event handler for the range change
  const handleRangeChange = (e) => {
    setRangeValue(parseInt(e.target.value, 10));
    console.log("e.target.value", e.target.value);
  };
  // var apartmentSettings = {
  //   dots: false,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 3,
  //   slidesToScroll: 1,
  //   responsive: [
  //     {
  //       breakpoint: 992,
  //       settings: {
  //         slidesToShow: 2,
  //         slidesToScroll: 1,
  //       },
  //     },
  //     {
  //       breakpoint: 768,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //       },
  //     },
  //   ],
  // };

  const { properties, total, limit, page } = useSelector(propertySelector);
  const { token, userData } = useSelector(authSelector);
  const { categories } = useSelector(categorySelector);

  const [configObject] = useState({
    headers: {
      Authorization: "Bearer " + token,
    },
    params: {
      limit: limit,
      page: page,
    },
  });

  useEffect(() => {
    getProperties();
    getCategories();
  }, []);

  const getProperties = async () => {
    await dispatch(getPropertiesThunk({ ...configObject }));
  }

  const getCategories = async () => {
    await dispatch(getCategoriesThunk({ ...configObject }));
  }

  const handleTabSelect = async (selected) => {
    setSelectedTab(selected)
    await dispatch(getPropertiesThunk({
      ...configObject, params: {
        category: selected
      }
    }));
  };

  const formik = useFormik({
    initialValues: {
      category: selectedTab,
      funding_status: "",
      country: "",
      rent_status: ""
    },
    onSubmit: async (values) => {
      setSelectedTab(values.category);
      await dispatch(getPropertiesThunk({
        ...configObject, params: {
          ...values
        }
      }));
    }
  });


  const propertyDetailPage = (property) => {
    if (token) {
      navigate('/propertydetails', { state: { property: property } })
    } else {
      navigate('/login')
    }
  }


  return (
    <>
      <Header />
      {/* ====== common banner ======= */}
      {/* <div className="static_banner position-relative">
        <Container>
          <div className="text-center">
            <h3>Invest</h3>
            <p>
              With an unwavering commitment to excellence, CrowdFina is on an{" "}
              <br /> odyssey to revolutionize the landscape of real estate.
            </p>
          </div>
          <Breadcrumb className="pages-breadcrumbs">
            <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
            <Breadcrumb.Item active>Invest</Breadcrumb.Item>
          </Breadcrumb>
        </Container>
        <img src={require("../../images/banner-wave.png")} alt="wave" className="static-banner-shape"  />
      </div> */}

      {/* <div className="investment-section common-padding">
        <Container>
          <Row>
            <Col lg={6}>
              <div className="common-heading-section pe-5">
                <h2>
                  Investment Clients can start investing from 10€ list{" "}
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="45"
                      height="27"
                      viewBox="0 0 45 27"
                      fill="none"
                    >
                      <path
                        d="M0.284615 1.67336C0.294956 1.30874 0.44442 0.961891 0.70235 0.703961C0.96028 0.446031 1.30713 0.296568 1.67175 0.286227L11.6334 0.000588459C11.9192 -0.00761844 12.201 0.0701779 12.4421 0.223905C12.6833 0.377632 12.8727 0.60021 12.9859 0.862819C13.0991 1.12543 13.1309 1.41598 13.0771 1.69684C13.0233 1.97771 12.8864 2.23596 12.6842 2.43816L10.8886 4.23375L22.2431 15.5918L28.7799 9.05533C29.5905 8.24476 30.6724 7.76283 31.8172 7.70237C32.9619 7.64192 34.0886 8.00723 34.9801 8.72792L43.7631 15.8289C44.0115 16.0297 44.2119 16.2835 44.3495 16.5718C44.4872 16.8601 44.5586 17.1754 44.5586 17.4949V25.4189C44.5586 25.6885 44.4822 25.9526 44.3383 26.1807C44.1945 26.4087 43.989 26.5914 43.7457 26.7076C43.5023 26.8237 43.2311 26.8686 42.9633 26.8371C42.6955 26.8055 42.4421 26.6988 42.2324 26.5293L32.3911 18.5725L25.5251 25.4381C24.6546 26.3085 23.474 26.7975 22.2431 26.7975C21.0121 26.7975 19.8316 26.3085 18.9611 25.4381L4.32212 10.7992L2.43726 12.684C2.23506 12.8862 1.97681 13.0231 1.69595 13.0769C1.41508 13.1307 1.12453 13.0989 0.861923 12.9857C0.599313 12.8725 0.376735 12.6831 0.223008 12.4419C0.0692809 12.2008 -0.00851486 11.9191 -0.000307954 11.6332L0.284615 1.67336Z"
                        fill="#8BC34A"
                      />
                    </svg>
                  </span>
                </h2>
                <p className="pe-5">
                  Displays Properties based on their type and starting with the
                  ones that are opened for investment
                </p>
                <small className="text-muted">
                  Venessa has captured some of the most photojournalistic images
                  with nearly every step, my microspikes slipped off the soles
                  of my chukka boots.
                </small>
              </div>
            </Col>
            <Col lg={6}>
              <div className="investment-images position-relative">
                <img
                  src={require("../../images/authbanner1.png")}
                  alt=""
                  className="imgBig position-relative z-2"
                />
                <img
                  src={require("../../images/landing-banner.png")}
                  alt=""
                  className="imgSmall d-none d-sm-block"
                />
                <div className="investor-shape d-none d-sm-block"></div>
              </div>
            </Col>
          </Row>
        </Container>
      </div> */}
      {
        !comingsoon ? <div className="common-padding apartment-section">
          <Container>
            {/* <p className="common-badge">Buildings</p> */}
            <Row>
              <Col lg={8}>
                <div className="common-heading-section mb-5">
                  <h2>Properties list</h2>
                  <p className="pb-4">
                    It is a long established fact that a reader will be distracted
                    by the readable content of a page when looking at its layout.
                  </p>
                </div>
              </Col>
            </Row>
            {/* <Slider {...apartmentSettings}> */}
            <div className="position-relative">
              <div className="filter-btn" onClick={handleShow}>
                <span className="me-2">
                  <FontAwesomeIcon icon={faFilter} />
                </span>
                Filter
              </div>

              <Tabs
                activeKey={selectedTab}
                //defaultActiveKey=""
                id="uncontrolled-tab-example"
                className="invest-tabs mb-3 border-0"
                onSelect={handleTabSelect}
              >
                <Tab eventKey="" title="All">
                  <Row>
                    {
                      total > 0 && properties.map((item) => {

                        return (<Col lg={4} key={item?._id} onClick={() => propertyDetailPage(item)}>

                          <div className="assets_performance-card mx-0 position-relative">
                            <Card className="border-0">
                              <CardBody>
                                <div className="top-img mb-4">

                                  {
                                    item?.property_images?.length > 0 ? <img
                                      src={`${process.env.REACT_APP_SERVER_URL}/uploads/properties/original/${item?.property_images[0]?.image}`}
                                      alt=""
                                    />
                                      : <img
                                        src={require("../../images/authbanner.png")}
                                        alt=""
                                      />
                                  }

                                </div>
                                <div className=" flex-wrap ROI_incom">
                                  <h4 className="exptd-incm mb-0">
                                    {item?.name}
                                  </h4>
                                  <p className="text-muted">
                                    {item?.category?.title}
                                  </p>
                                </div>

                                <div className="d-flex align-items-center gap-2">
                                  <p className="text-muted mb-0">{item?.address} {item?.location}, {item?.city}, {item?.country}, {item?.postal_code}</p>
                                  <span className="text-muted">|</span>
                                  <p className="text-muted mb-0">1k investors</p>
                                </div>
                              </CardBody>
                            </Card>
                            <div className="funded-pill text-capitalize">{item?.funding_status.replaceAll("_", " ")}</div>
                          </div>
                        </Col>)
                      })
                    }
                  </Row>
                </Tab>
                {
                  categories.length > 0 && categories.map((category) => {
                    return (
                      <Tab eventKey={category?._id} title={category?.title} key={category?._id}>
                        <Row>
                          {
                            total > 0 && properties.map((item) => {
                              return (

                                <Col lg={4} key={item?._id} onClick={() => propertyDetailPage(item)}>

                                  <div className="assets_performance-card mx-0 position-relative">
                                    <Card className="border-0">
                                      <CardBody>
                                        <div className="top-img mb-4">

                                          {
                                            item?.property_images?.length > 0 ? <img
                                              src={`${process.env.REACT_APP_SERVER_URL}/uploads/properties/original/${item?.property_images[0]?.image}`}
                                              alt=""
                                            />
                                              : <img
                                                src={require("../../images/authbanner.png")}
                                                alt=""
                                              />
                                          }

                                        </div>
                                        <div className=" flex-wrap ROI_incom">
                                          <h4 className="exptd-incm mb-0">
                                            {item?.name}
                                          </h4>
                                          <p className="text-muted">
                                            {item?.category?.title}
                                          </p>
                                        </div>

                                        <div className="d-flex align-items-center gap-2">

                                          <p className="text-muted mb-0">{item?.address} {item?.location}, {item?.city}, {item?.country}, {item?.postal_code}</p>


                                          <span className="text-muted">|</span>
                                          <p className="text-muted mb-0">1k investors</p>
                                        </div>
                                      </CardBody>
                                    </Card>
                                    <div className="funded-pill text-capitalize">{item?.funding_status.replaceAll("_", " ")}</div>
                                  </div>
                                </Col>)
                            })
                          }
                        </Row>
                      </Tab>
                    )


                  })
                }
              </Tabs>
            </div>

            {/* </Slider> */}
          </Container>
        </div> :
          <>
          <div className="static_banner coming-soon-banner position-relative">
        <Container>
        <Row className="justify-content-center">
              <Col lg={12}> 
              <div className="text-center mx-auto">
                <img
                    src={require("../../images/comming_soon.png")}
                    alt="coming soon"
                    className="w-100 coming-soon-img"
                  />
               </div>
              </Col>
            </Row>
        </Container>
        <img src={require("../../images/banner-wave.png")} alt="wave" className="static-banner-shape"  />
      </div></>

      }



      {/* <div className="video-cta py-5">
        <Container>
          <div className="position-relative blue-bg">
            <Row className="align-items-center">
              <Col lg={6}>
                <div className="video-img mb-3">
                  <img
                    src={require("../../images/video-cta-img.png")}
                    alt="image"
                  />
                </div>
              </Col>
              <Col lg={5}>
                <div className="common-heading-section">
                  <h2 className="text-white">Learning Center</h2>
                  <p className="text-white">
                    How to Start Investing with CrowdFina
                  </p>
                  <small>
                    Venessa has captured some of the most photojournalistic
                    images with nearly every step, my microspikes slipped off
                    the soles of my chukka boots.
                  </small>
                </div>
              </Col>
            </Row>
            <div className="shape-blue position-absolute d-none d-md-block">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="135"
                height="205"
                viewBox="0 0 135 205"
                fill="none"
              >
                <rect
                  x="23.3379"
                  y="70.3408"
                  width="113.335"
                  height="113.335"
                  rx="19"
                  transform="rotate(11.5097 23.3379 70.3408)"
                  fill="#55BCC9"
                />
                <rect
                  x="67.9844"
                  y="0.731445"
                  width="56.6673"
                  height="56.6673"
                  rx="15"
                  transform="rotate(11.5097 67.9844 0.731445)"
                  fill="#55BCC9"
                />
              </svg>
            </div>
          </div>
        </Container>
      </div> */}
      {/* <div className="common-padding choooseUs-section">
        <Container>
          <Row>
            <Col lg={6}>
              <p className="common-badge">Why Choose us</p>

              <div className="common-heading-section">
                <h2 className="">We Help You to GrowYour Business Quickly</h2>
                <p className="">
                  Leverage agile frameworks to provide a robust synopsis for
                  high level overviews. Iterative approaches to corporate
                  strategy foster collaborative thinking to further the overall
                  value proposition. Organically grow the holistic world view of
                  disruptive innovation via workplace diversity and empowerment.
                </p>
              </div>
            </Col>
            <Col lg={6}>
              <Card className="border-0 choooseUs-progress-card">
                <CardBody>
                  <div className="mb-4">
                    <div className="d-flex align-items-center justify-content-between mb-4 flex-wrap">
                      <p className="progress-heading">Success Rate</p>
                      <span className="valueSpan">{rangeValue}</span>
                    </div>
                    <div className="growBusiness_progress position-relative">
                      <Form.Range
                        value={rangeValue}
                        onChange={handleRangeChange}
                        min={0}
                        max={100}
                      />
                      <span
                        className="filledProgress"
                        style={{ width: `${rangeValue}%` }}
                      ></span>
                      <span
                        className="controller"
                        style={{ left: `calc(${rangeValue}% - 9px)` }}
                      ></span>
                    </div>
                  </div>
                  <div className="mb-4">
                    <div className="d-flex align-items-center justify-content-between mb-4 flex-wrap">
                      <p className="progress-heading">Total Property</p>
                      <span className="valueSpan">{rangeValue}</span>
                    </div>
                    <div className="growBusiness_progress position-relative">
                      <Form.Range
                        value={rangeValue}
                        onChange={handleRangeChange}
                        min={0}
                        max={100}
                      />
                      <span
                        className="filledProgress"
                        style={{ width: `${rangeValue}%` }}
                      ></span>
                      <span
                        className="controller"
                        style={{ left: `calc(${rangeValue}% - 9px)` }}
                      ></span>
                    </div>
                  </div>
                  <div className="mb-4">
                    <div className="d-flex align-items-center justify-content-between mb-4 flex-wrap">
                      <p className="progress-heading">Real Estate Investing</p>
                      <span className="valueSpan">{rangeValue}</span>
                    </div>
                    <div className="growBusiness_progress position-relative">
                      <Form.Range
                        value={rangeValue}
                        onChange={handleRangeChange}
                        min={0}
                        max={100}
                      />
                      <span
                        className="filledProgress"
                        style={{ width: `${rangeValue}%` }}
                      ></span>
                      <span
                        className="controller"
                        style={{ left: `calc(${rangeValue}% - 9px)` }}
                      ></span>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div> */}

      <Footer />

      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="end"
        className="filter-offcanvas"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Filter</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <form onSubmit={formik.handleSubmit}>
            <p className="mb-1 fw-semibold">Type</p>
            <div className="d-flex align-items-center gap-2 border-bottom pb-2 mb-2">
              <Form.Group controlId="validationCustom01">
                <Form.Control type="radio" name="category" value="" onChange={formik.handleChange} />
                <Form.Label>All</Form.Label>
              </Form.Group>
              {
                categories.length > 0 && categories.map((item) => {
                  return (
                    <Form.Group controlId={item?._id} key={item?._id}>
                      <Form.Control type="radio" name="category" value={item?._id} onChange={formik.handleChange} />
                      <Form.Label>{item?.title}</Form.Label>
                    </Form.Group>
                  )
                })
              }
            </div>

            <p className="mb-1 fw-semibold">Availability</p>
            <div className="d-flex align-items-center gap-2 border-bottom pb-2 mb-2">
              <Form.Group controlId="All">
                <Form.Control type="radio" name="funding_status" value="" onChange={formik.handleChange} />
                <Form.Label>All</Form.Label>
              </Form.Group>
              <Form.Group controlId="funding_starting_soon">
                <Form.Control type="radio" name="funding_status" value="funding_starting_soon" onChange={formik.handleChange} />
                <Form.Label>Funding Starting Soon</Form.Label>
              </Form.Group>
              <Form.Group controlId="funding_in_progress">
                <Form.Control type="radio" name="funding_status" value="funding_in_progress" onChange={formik.handleChange} />
                <Form.Label>Funding In Progress</Form.Label>
              </Form.Group>
              <Form.Group controlId="fully_funded">
                <Form.Control type="radio" name="funding_status" value="fully_funded" onChange={formik.handleChange} />
                <Form.Label>Fully funded</Form.Label>
              </Form.Group>
            </div>

            <p className="mb-1 fw-semibold">Location</p>
            <div className="border-bottom pb-2 mb-3">
              <ReactFlagsSelect
                searchable
                searchPlaceholder="Search country"
                selected={formik.values.country}
                onSelect={(code) => formik.setFieldValue("country", code)}
              />
              {/* <Form.Select aria-label="Default select example" name="location" onChange={formik.handleChange}>
                <option value="">All 60 Locations</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </Form.Select> */}
            </div>

            <p className="mb-1 fw-semibold">Rent status</p>
            <div className="d-flex align-items-center gap-2 border-bottom pb-2 mb-2">
              <Form.Group controlId="All2">
                <Form.Control type="radio" name="rent_status" value="" onChange={formik.handleChange} />
                <Form.Label>All</Form.Label>
              </Form.Group>
              <Form.Group controlId="Rented">
                <Form.Control type="radio" name="rent_status" value="rented" onChange={formik.handleChange} />
                <Form.Label>Rented</Form.Label>
              </Form.Group>
              <Form.Group controlId="SeekingTenent">
                <Form.Control type="radio" name="rent_status" value="seeking_tenent" onChange={formik.handleChange} />
                <Form.Label>Seeking Tenent</Form.Label>
              </Form.Group>
            </div>
            <div className="mt-5">
              <Row>
                <Col md={6}>
                  <Button className="w-100 btn-secondary" type="reset">Clear Filters</Button>
                </Col>
                <Col md={6}>
                  <Button className="w-100" type="submit">Show Results</Button>
                </Col>
              </Row>
            </div>
          </form>

        </Offcanvas.Body>
        {/* <Offcanvas.Footer>
          <Button varient="primary">Show Results</Button>
        </Offcanvas.Footer> */}
      </Offcanvas>
    </>
  );
}

export default Invest;
