import React, { useState } from 'react';
import Header from '../common/header';
import Footer from '../common/footer';
import { Breadcrumb, Button, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faComment, faUser } from '@fortawesome/free-solid-svg-icons';

function BLogDetail() {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <Header />

            {/* ====== common banner ======= */}
            <div className="static_banner position-relative">
                <Container>
                    <div className="text-center">
                        <h3>Blog Detail</h3>
                    </div>
                    <Breadcrumb className="pages-breadcrumbs">
                        <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                        <Breadcrumb.Item active>Blog Detail</Breadcrumb.Item>
                    </Breadcrumb>
                </Container>
                <img src={require("../../images/banner-wave.png")} alt="wave" className="static-banner-shape" />
            </div>


            <div className="common-padding">
                <Container>
                    <Row>
                        <Col lg={12} className="mb-3">
                            <div className="p-3 position-relative">
                                <div className="blog_img rounded-4 blog_detail_img">
                                    <img
                                        src={require("../../images/faq.png")}
                                        alt="blog"
                                        className="w-100 rounded-4 h-100 object-fit-cover"
                                    />
                                </div>
                                <div className="blog_content_wrapper py-4 mx-auto mt-2 mw-100 shadow-none bg-none">
                                    <ul className="ps-0 list-unstyled d-flex flex-wrap gap-4">
                                        <li className="d-flex gap-2 align-items-center">
                                            <div className="d-flex align-items-center justify-content-center admin_icon">
                                                <FontAwesomeIcon icon={faUser} />
                                            </div>
                                            <p className="mb-0">Admin</p>
                                        </li>
                                        <li className="d-flex gap-2 align-items-center">
                                            <div className="d-flex align-items-center justify-content-center admin_icon">
                                                <FontAwesomeIcon icon={faCalendar} />
                                            </div>
                                            <p className="mb-0">20 December 2023</p>
                                        </li>
                                        <li className="d-flex gap-2 align-items-center comment_hover position-relative" onClick={handleShow}>
                                            <div className="d-flex align-items-center justify-content-center admin_icon">
                                                <FontAwesomeIcon icon={faComment} />
                                            </div>
                                            <p className="mb-0">Comment (7)</p>
                                            {/* <span className='comment_counts position-absolute'>7</span> */}
                                        </li>
                                    </ul>

                                    <Modal show={show} onHide={handleClose} centered>
                                        <Modal.Header closeButton>
                                            <Modal.Title>Comments</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <h6>Leave a comment</h6>
                                            <Form.Control
                                                as="textarea"
                                                placeholder="Leave a comment here"
                                                style={{ height: '100px' }}
                                            />
                                            <Button variant="primary" onClick={handleClose} className='mt-4'>
                                                Add Comment
                                            </Button>
                                        </Modal.Body>

                                    </Modal>
                                    <h4 className="blog_heading pe-4 mt-3">
                                        We Help You to GrowYour Business Quickly
                                    </h4>
                                    <p className="mb-0 blog_description">
                                        Venessa has captured some of the most photojournalistic
                                        images with nearly every step, my microspikes slipped off
                                        the soles of my chukka boots.{" "}
                                    </p>
                                    <h4 className="blog_heading pe-4 mt-3">
                                        Introduction
                                    </h4>
                                    <p className="mb-0 blog_description">
                                        Venessa has captured some of the most photojournalistic
                                        images with nearly every step, my microspikes slipped off
                                        the soles of my chukka boots. Venessa has captured some of the most photojournalistic
                                        images with nearly every step, my microspikes slipped off
                                        the soles of my chukka boots. Venessa has captured some of the most photojournalistic
                                        images with nearly every step, my microspikes slipped off
                                        the soles of my chukka boots. Venessa has captured some of the most photojournalistic
                                        images with nearly every step, my microspikes slipped off
                                        the soles of my chukka boots.
                                    </p>
                                    <h4 className="blog_heading pe-4 mt-3">
                                        Introduction
                                    </h4>
                                    <p className="mb-0 blog_description">
                                        Venessa has captured some of the most photojournalistic
                                        images with nearly every step, my microspikes slipped off
                                        the soles of my chukka boots. Venessa has captured some of the most photojournalistic
                                        images with nearly every step, my microspikes slipped off
                                        the soles of my chukka boots. Venessa has captured some of the most photojournalistic
                                        images with nearly every step, my microspikes slipped off
                                        the soles of my chukka boots. Venessa has captured some of the most photojournalistic
                                        images with nearly every step, my microspikes slipped off
                                        the soles of my chukka boots.
                                    </p>

                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Container>
                    <div className="comments-section mb-5 bg-white shadow rounded-4">
                        <h4 className='mb-4'>Comments</h4>

                        <ul className="comments list-unstyled p-0 m-0">
                            <li>
                                <div className="comment d-flex gap-4 w-100 pb-3">
                                    <div className="comment-author">
                                        <a href="#">
                                            <img
                                                src={require("../../images/testimonial-user.png")}
                                                alt="investor"
                                                className="rounded-circle"
                                            />
                                        </a>
                                    </div>

                                    
                                    <div className="comment-content pb-3 w-100">
                                        <div className="d-flex justify-content-between mb-2">
                                            <div className="comment-meta-author">
                                                John Antony
                                            </div>
                                            <div className="comment-meta-date">
                                                26 December 2023
                                            </div>
                                        </div>
                                        <div className="comment-body">
                                            <p className='mb-0 text-muted'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec luctus tincidunt aliquam. Aliquam gravida massa at sem vulputate.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec luctus tincidunt aliquam. Aliquam gravida massa at sem vulputate.</p>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="comment d-flex gap-4 w-100 pb-3">
                                    <div className="comment-author">
                                        <a href="#">
                                            <img
                                                src={require("../../images/testimonial-user.png")}
                                                alt="investor"
                                                className="rounded-circle"
                                            />
                                        </a>
                                    </div>
                                    <div className="comment-content pb-3 w-100">
                                        <div className="d-flex justify-content-between mb-2">
                                            <div className="comment-meta-author">
                                                John Antony
                                            </div>
                                            <div className="comment-meta-date">
                                                26 December 2023
                                            </div>
                                        </div>
                                        <div className="comment-body">
                                            <p className='mb-0 text-muted'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec luctus tincidunt aliquam. Aliquam gravida massa at sem vulputate.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec luctus tincidunt aliquam. Aliquam gravida massa at sem vulputate.</p>
                                        </div>
                                    </div>
                                </div>
                            </li>

                        </ul>
                    </div>
                </Container>


                <Container>
                    <Row>
                        <Col lg={4} className="mb-3">
                            <div className="p-3 position-relative">
                                <div className="blog_img">
                                    <img
                                        src={require("../../images/faq.png")}
                                        alt="blog"
                                        className="w-100"
                                    />
                                </div>
                                <div className="blog_content_wrapper p-4 mx-auto">
                                    <ul className="ps-0 list-unstyled d-flex flex-wrap gap-4">
                                        <li className="d-flex gap-2 align-items-center">
                                            <div className="d-flex align-items-center justify-content-center admin_icon">
                                                <FontAwesomeIcon icon={faUser} />
                                            </div>
                                            <p className="mb-0">Admin</p>
                                        </li>
                                        <li className="d-flex gap-2 align-items-center">
                                            <div className="d-flex align-items-center justify-content-center admin_icon">
                                                <FontAwesomeIcon icon={faCalendar} />
                                            </div>
                                            <p className="mb-0">20 December 2023</p>
                                        </li>
                                        <li className="d-flex gap-2 align-items-center">
                                            <div className="d-flex align-items-center justify-content-center admin_icon">
                                                <FontAwesomeIcon icon={faComment} />
                                            </div>
                                            <p className="mb-0">Comment</p>
                                        </li>
                                    </ul>
                                    <h4 className="blog_heading pe-4">
                                        We Help You to GrowYour Business Quickly
                                    </h4>
                                    <p className="mb-0 blog_description">
                                        Venessa has captured some of the most photojournalistic
                                        images with nearly every step, my microspikes slipped off
                                        the soles of my chukka boots.{" "}
                                    </p>

                                    <Button className="blog_btn px-4 mt-4">Read More</Button>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4} className="mb-3">
                            <div className="p-3 position-relative">
                                <div className="blog_img">
                                    <img
                                        src={require("../../images/faq.png")}
                                        alt="blog"
                                        className="w-100"
                                    />
                                </div>
                                <div className="blog_content_wrapper p-4 mx-auto">
                                    <ul className="ps-0 list-unstyled d-flex flex-wrap gap-4">
                                        <li className="d-flex gap-2 align-items-center">
                                            <div className="d-flex align-items-center justify-content-center admin_icon">
                                                <FontAwesomeIcon icon={faUser} />
                                            </div>
                                            <p className="mb-0">Admin</p>
                                        </li>
                                        <li className="d-flex gap-2 align-items-center">
                                            <div className="d-flex align-items-center justify-content-center admin_icon">
                                                <FontAwesomeIcon icon={faCalendar} />
                                            </div>
                                            <p className="mb-0">20 December 2023</p>
                                        </li>
                                        <li className="d-flex gap-2 align-items-center">
                                            <div className="d-flex align-items-center justify-content-center admin_icon">
                                                <FontAwesomeIcon icon={faComment} />
                                            </div>
                                            <p className="mb-0">Comment</p>
                                        </li>
                                    </ul>
                                    <h4 className="blog_heading pe-4">
                                        We Help You to GrowYour Business Quickly
                                    </h4>
                                    <p className="mb-0 blog_description">
                                        Venessa has captured some of the most photojournalistic
                                        images with nearly every step, my microspikes slipped off
                                        the soles of my chukka boots.{" "}
                                    </p>

                                    <Button className="blog_btn px-4 mt-4">Read More</Button>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4} className="mb-3">
                            <div className="p-3 position-relative">
                                <div className="blog_img">
                                    <img
                                        src={require("../../images/faq.png")}
                                        alt="blog"
                                        className="w-100"
                                    />
                                </div>
                                <div className="blog_content_wrapper p-4 mx-auto">
                                    <ul className="ps-0 list-unstyled d-flex flex-wrap gap-4">
                                        <li className="d-flex gap-2 align-items-center">
                                            <div className="d-flex align-items-center justify-content-center admin_icon">
                                                <FontAwesomeIcon icon={faUser} />
                                            </div>
                                            <p className="mb-0">Admin</p>
                                        </li>
                                        <li className="d-flex gap-2 align-items-center">
                                            <div className="d-flex align-items-center justify-content-center admin_icon">
                                                <FontAwesomeIcon icon={faCalendar} />
                                            </div>
                                            <p className="mb-0">20 December 2023</p>
                                        </li>
                                        <li className="d-flex gap-2 align-items-center">
                                            <div className="d-flex align-items-center justify-content-center admin_icon">
                                                <FontAwesomeIcon icon={faComment} />
                                            </div>
                                            <p className="mb-0">Comment</p>
                                        </li>
                                    </ul>
                                    <h4 className="blog_heading pe-4">
                                        We Help You to GrowYour Business Quickly
                                    </h4>
                                    <p className="mb-0 blog_description">
                                        Venessa has captured some of the most photojournalistic
                                        images with nearly every step, my microspikes slipped off
                                        the soles of my chukka boots.{" "}
                                    </p>

                                    <Button className="blog_btn px-4 mt-4">Read More</Button>
                                </div>
                            </div>
                        </Col>

                    </Row>
                </Container>
            </div>


            <Footer />
        </>
    )
}

export default BLogDetail