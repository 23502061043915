export const transactionSelector = (state) => {
    const {
        transactions,
        total,
        isLoading,
        limit,
        page,
        walletBalance
    } = state.transaction

    return {
        transactions,
        total,
        isLoading,
        limit,
        page,
        walletBalance
    }
}