import { configureStore } from "@reduxjs/toolkit";

import authSlice from "./features/auth/authSlice";
import propertySlice from "./features/property/propertySlice";
import categorySlice from "./features/category/categorySlice";
import transactionSlice from "./features/transaction/transactionSlice";
import investmentSlice from "./features/investment/investmentSlice";
import cardSlice from "./features/card/cardSlice";
import tutorialSlice from "./features/tutorial/tutorialSlice";
import faqSlice from "./features/faq/faqSlice";

export const store = configureStore({
  reducer: {
    auth: authSlice,
    property: propertySlice,
    category: categorySlice,
    transaction: transactionSlice,
    investment: investmentSlice,
    card: cardSlice,
    tutorial: tutorialSlice,
    faq: faqSlice
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
