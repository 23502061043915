import { createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import {
  forgotPasswordThunk,
  loginThunk,
  registerThunk,
  resetPasswordThunk,
  updateProfileThunk,
  updateDetailsThunk,
  logoutThunk,
  socialLoginThunk,
  updateImageThunk,
  getProfileThunk
} from "./authThunk";


const initialState = {

  isLoading: false,
  userData:
    localStorage.getItem("user") !== undefined
      ? JSON.parse(localStorage.getItem("user"))
      : "",
  token:
    localStorage.getItem("userToken") ??
    localStorage.getItem("tempToken"),

  otpVerified: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      // login thunk
      .addCase(loginThunk.fulfilled, (state, action) => {
        console.log(action?.payload);
        if (action?.payload?.status === 200) {
          localStorage.setItem('user', JSON.stringify(action?.payload?.data?.body?.user));
          localStorage.setItem('userToken', action?.payload?.data?.body?.token);
          state.token = action?.payload?.data?.body?.token;
          state.userData = action?.payload?.data?.body?.user;
          state.isLoggedIn = true;
          toast.success("Login successfully");
        } else {
          toast.error(action?.payload?.response?.data?.message);
        }
      })

      .addCase(registerThunk.fulfilled, (state, action) => {
        if (action?.payload?.response?.status === 400) {
          toast.error(action?.payload?.response?.data?.message);
        } else {
          localStorage.setItem('user', JSON.stringify(action?.payload?.data?.body?.user));
          localStorage.setItem('userToken', action?.payload?.data?.body?.token);
          state.userData = { ...action?.payload?.data?.data?.user };
          toast.success(action?.payload?.data?.message);
        }
      })


      .addCase(resetPasswordThunk.fulfilled, (state, action) => {
        if (action?.payload?.status === 200) {
          toast.success("Password reset successfully");
        } else {
          toast.error(action?.payload?.response?.data?.message);
        }
      })

      .addCase(updateProfileThunk.fulfilled, (state, action) => {

        if (action?.payload?.status === 200) {
          const user = action.payload.data?.body;
          localStorage.setItem("user", JSON.stringify(user));
          state.userData = user;
        } else {
          console.log(action.payload, "payloaddd");
        }
      })

      .addCase(updateDetailsThunk.fulfilled, (state, action) => {
        if (action?.payload?.status === 200) {
          const user = action.payload.data?.body;
          ////toast.success("User profile updated successfully");

          localStorage.setItem("user", JSON.stringify(user));
          state.userData = user;
        } else {
          console.log(action.payload, "payloaddd");
        }
      })

      .addCase(logoutThunk.fulfilled, (state, action) => {
        state.userData = "";
        state.token = "";
        state.isLoggedIn = false;
      })

      .addCase(socialLoginThunk.fulfilled, (state, action) => {
        if (action?.payload?.status === 200) {
          localStorage.setItem('user', JSON.stringify(action?.payload?.data?.body?.user));
          localStorage.setItem('userToken', action?.payload?.data?.body?.token);
          state.token = action?.payload?.data?.body?.token;
          state.userData = action?.payload?.data?.body?.user;
          state.isLoggedIn = true;
        } else {
          toast.error(action?.payload?.response?.data?.message);
        }
      })

      .addCase(updateImageThunk.fulfilled, (state, action) => {

        if (action?.payload?.status === 200) {
          const user = action.payload.data?.body;
          localStorage.setItem("user", JSON.stringify(user));
          state.userData = user;
        }
      })

      .addCase(getProfileThunk.fulfilled, (state, action) => {
        if (action?.payload?.status === 200) {
          const user = action.payload.data?.body;
          localStorage.setItem("user", JSON.stringify(user));
          state.userData = user;
        } else {
          console.log(action.payload, "payloaddd");
        }
      })
  },
});

export default authSlice.reducer;
