import React, { useState } from "react";
import { useFormik } from "formik";
import {
    Button,
    Col,
    Container,
    Form,
    Modal,
    Row,
    Table,
} from "react-bootstrap";
import { addCardSchema } from "../../../utils/validationsSchema";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { addCardThunk } from "../../../features/card/cardThunk";
import { authSelector } from "../../../features/auth/authSelector";

export default function AddCard(props) {
    const dispatch = useDispatch();
    const { userData, token } = useSelector(authSelector);
    const [configObject, setConfigObject] = useState({
        headers: {
            Authorization: "Bearer " + token,
        },
    });

    const formik = useFormik({
        initialValues: {
            card_no: "",
            holder_name: "",
            exp_date: "",
            cvv: "",
        },
        validationSchema: addCardSchema,
        onSubmit: async (values, { resetForm }) => {
            let payload = {
                ...values,
                card_no: values.card_no.toString(),
                cvv: values.cvv.toString()
            }
            try {
                const response = await dispatch(addCardThunk({ ...configObject, body: payload }));

                if (response?.payload?.status === 200) {
                    toast.success('Card saved successfully');
                    props.onClose();
                    resetForm();
                } else {
                    toast.error("Something went wrong.");
                }
            } catch (error) {
                toast.error("Something went wrong.");
            }
        },
    });

    return (
        <Modal show={props.show} onHide={props.onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Add Card</Modal.Title>
            </Modal.Header>
            <Form onSubmit={formik.handleSubmit}>
                <Modal.Body>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Card Number</Form.Label>
                        <Form.Control
                            type="number"
                            placeholder="Enter card number"
                            name="card_no"
                            value={formik.values.card_no}
                            onChange={formik.handleChange}
                        />
                        <span style={{ color: "red" }}>
                            {formik.errors.card_no ? (
                                <div>{formik.errors.card_no} </div>
                            ) : null}{" "}
                        </span>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                        <Form.Label>Card Holder Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Name"
                            name="holder_name"
                            value={formik.values.holder_name}
                            onChange={formik.handleChange}
                        />
                        <span style={{ color: "red" }}>
                            {formik.errors.holder_name ? (
                                <div>{formik.errors.holder_name} </div>
                            ) : null}{" "}
                        </span>
                    </Form.Group>
                    <Row>
                        <Col md={6}>
                            <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlInput2"
                            >
                                <Form.Label>Expire Date</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="MM/YYYY"
                                    name="exp_date"
                                    value={formik.values.exp_date}
                                    onChange={formik.handleChange}
                                />
                                <span style={{ color: "red" }}>
                                    {formik.errors.exp_date ? (
                                        <div>{formik.errors.exp_date} </div>
                                    ) : null}{" "}
                                </span>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlInput2"
                            >
                                <Form.Label>CVV</Form.Label>
                                <Form.Control
                                    type="number"
                                    placeholder="CVV"
                                    name="cvv"
                                    value={formik.values.cvv}
                                    onChange={formik.handleChange}
                                />
                                <span style={{ color: "red" }}>
                                    {formik.errors.cvv ? (
                                        <div>{formik.errors.cvv} </div>
                                    ) : null}{" "}
                                </span>
                            </Form.Group>
                        </Col>
                    </Row>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" type="submit" className="w-100">
                        Add Card
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}
