import React, { useState, useEffect } from 'react'
import { Button, Container, Form, Modal, Nav, Navbar } from 'react-bootstrap'
import { Link, useNavigate, useLocation } from "react-router-dom";

import { useFormik } from "formik";
import { subscriberSchema } from "../../utils/validationsSchema";
import { subscribeThunk, logoutThunk } from "../../features/auth/authThunk";
import { toast } from "react-hot-toast";
import { authSelector } from "../../features/auth/authSelector";
import { useDispatch, useSelector } from "react-redux"
import moment from "moment";

function Header() {
  const { token, userData } = useSelector(authSelector);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [show, setShow] = useState(true);
  const handleClose = () => setShow(false);
  const handleShowModal = () => setShow(true);

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: subscriberSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        const response = await dispatch(subscribeThunk(values));
        if (response?.payload?.status === 200) {
          toast.success('Subcribed successfully');
          resetForm();
        } else {
          toast.error('Email already subscribed')
        }
      } catch (error) {
        toast.error("Something went wrong.", {
          position: "bottom-left",
        });
      }
    },
  });

  const logout = async () => {
    const response = await dispatch(logoutThunk());
    if (response) {
      toast.success('Logout successfully');
      navigate("/login");
    }
  }

  useEffect(() => {
    console.log(token)
    if (token) {
      var base64Url = token.split('.')[1];
      var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
      var jwt = JSON.parse(jsonPayload);

      if (moment().unix() > jwt.exp) {
        localStorage.removeItem("userToken");
        localStorage.removeItem("user");
        navigate('/login')
      }
    }
  }, []);

  return (
    <div className="guestHeader">

      <Navbar expand="lg" className="bg-body-tertiary">
        <Container>
          <Navbar.Brand href="#home">
            <img src={require("../../images/logo.png")} alt="logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" className='border-0' />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="ms-auto"
              // style={{ maxHeight: '100px' }}
              navbarScroll
            >
              <Link className={`nav-link ${location.pathname === "/" ? "active" : ""}`} to="/">
                Home
              </Link>
              <Link to="/aboutus" className={`nav-link ${location.pathname === "/aboutus" ? "active" : ""}`}>
                About
              </Link>
              <Link className={`nav-link ${location.pathname === "/blog" ? "active" : ""}`} to="/blog">
                Blog
              </Link>
              <Link className={`nav-link ${location.pathname === "/invest" ? "active" : ""}`} to="/invest">
                Invest
              </Link>
            </Nav>
            {/* {!token ? (
              <div className="d-flex flex-wrap gap-3">
                <Link className="btn btn-primary header-login  px-4" to="/login">
                  Login
                </Link>
                <Link className=" btn btn-primary header-signup px-4" to="/signup">
                  Signup
                </Link>
              </div>
            ) : (
              <div className="d-flex flex-wrap gap-3">
                <Link className="btn btn-primary header-login  px-4" to="/portfolio">
                  Dashbord
                </Link>
                <Link
                  className=" btn btn-primary header-signup px-4"
                  to="/"
                  onClick={logout}
                >
                  Logout
                </Link>
              </div>
            )} */}
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {/* <Navbar>
          <Container>
            <Navbar.Brand href="#home">
              <img src={require("../../images/logo.png")} alt="logo" />
            </Navbar.Brand>
            <Nav className="ms-auto">
              {/* <Nav.Link href="/">Home</Nav.Link>
              <Nav.Link href="/aboutus">About</Nav.Link>
              <Nav.Link href="/blog">Blog</Nav.Link>
              <Nav.Link href="/invest">Invest</Nav.Link> */}
      {/* <Link className="nav-link" to="/">
                Home
              </Link>
              <Link className="nav-link" to="/aboutus">
                About
              </Link>
              <Link className="nav-link" to="/blog">
                Blog
              </Link>
              <Link className="nav-link" to="/invest">
                Invest
              </Link>
            </Nav> */}
      {/* <div className="d-flex flex-wrap gap-3">
              <Link className="btn btn-primary header-login px-4" to="/login"  >Login</Link>
              <Link className=" btn btn-primary header-signup px-4" to="/signup">Signup</Link>
            </div> */}
      {/* {!token ? (
              <div className="d-flex flex-wrap gap-3">
                <Link className="btn btn-primary header-login  px-4" to="/login">
                  Login
                </Link>
                <Link className=" btn btn-primary header-signup px-4" to="/signup">
                  Signup
                </Link>
              </div>
            ) : (
              <Link
                className=" btn btn-primary header-signup px-4"
                to="/login"
                onClick={() => {
                  localStorage.removeItem("userToken");
                  navigate("/login");
                }}
              >
                Logout
              </Link>
            )} */}
      {/* </Container> */}
      {/* </Navbar>  */}
      {/* <Button variant="primary" onClick={handleShowModal}>
        Coming Soon
      </Button> */}


      <Modal show={show} onHide={handleClose} className="comingSoon_modal" centered>
        <Modal.Body>
          <div>
            <div className="text-center p-4">
              <h1>CrowdFina is</h1>
              <img
                src={require("../../images/comming_soon.png")}
                alt="coming soon"
                className="comingSoon_img mb-4"
              />
            </div>
            <p className='fw-medium text-center h5 mb-4'>Unlocking doors to new possibilities . Stay tuned for your dream property.</p>
            <form onSubmit={formik.handleSubmit} className="mx-auto comingSoon_form position-relative" >
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Control
                  type="email"
                  placeholder="Your Email address"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                />
                <span style={{ color: "red" }}>
                  {formik.errors.email ? (
                    <div>{formik.errors.email} </div>
                  ) : null}{" "}
                </span>
              </Form.Group>
              <Button className="px-4" type="submit">Get Started</Button>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default Header
