import { createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import {
    getPropertiesThunk,
    getMyInvestedPropertiesThunk,
} from "./propertyThunk";


const initialState = {
    properties: [],
    total: 0,
    isLoading: false,
    limit: 8,
    page: 1,

    myInvestProperties: [],
    investTotal: 0,
    investLimit: 10,
    investPage: 1
};

const propertySlice = createSlice({
    name: "property",
    initialState,
    reducers: {
        changeLimit: (state, action) => {
            state.limit = action.payload;
        },
        changePage: (state, action) => {
            state.page = action.payload;
        },
        changeInvestLimit: (state, action) => {
            state.investLimit = action.payload;
        },
        changeInvestPage: (state, action) => {
            state.investPage = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getPropertiesThunk.fulfilled, (state, action) => {
                if (action?.payload?.status === 200) {
                    state.properties = action?.payload?.data?.body?.data;
                    state.total = action?.payload?.data?.body?.count;
                }
            })
            .addCase(getMyInvestedPropertiesThunk.fulfilled, (state, action) => {
                if (action?.payload?.status === 200) {
                    state.myInvestProperties = action?.payload?.data?.body?.data;
                    state.investTotal = action?.payload?.data?.body?.count;
                }
            })
    },
});

export const { changeLimit, changePage, changeInvestLimit, changeInvestPage } = propertySlice.actions;
export default propertySlice.reducer;
