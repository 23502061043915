import React, { useState, useEffect, useMemo } from "react";
import AdminSidebar from "../AdminSidebar";
import DashboardHeader from "../DashboardHeader";
import { Col, Container, Dropdown, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationPin } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import AdminNavBar from "../AdminNavBar";
import { getPropertiesThunk } from "../../../features/property/propertyThunk";
import { propertySelector } from "../../../features/property/propertySelector";
import {
  changeLimit,
  changePage,
} from "../../../features/property/propertySlice";
import { useDispatch, useSelector } from "react-redux";
import { authSelector } from "../../../features/auth/authSelector";
import ReactPaginate from "react-paginate";

function SingleInvest() {
  const [showContent, setShowContent] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toggleSidebar = () => setShowContent(!showContent);

  const { properties, total, limit, page } = useSelector(propertySelector);
  const { token, userData } = useSelector(authSelector);

  const [configObject] = useState({
    headers: {
      Authorization: "Bearer " + token,
    },
    params: {
      limit: limit,
      page: page,
    },
  });

  const pageCount = useMemo(() => {
    const pageCounter = Math.ceil(total / limit);
    return pageCounter;
  }, [total, limit]);

  useEffect(() => {
    getProperties();
  }, []);

  const getProperties = async () => {
    await dispatch(getPropertiesThunk({ ...configObject }));
  };

  const handlePageClick = async (data) => {
    await dispatch(changePage(data.selected + 1));
    await dispatch(
      getPropertiesThunk({
        ...configObject,
        params: {
          limit: limit,
          page: data.selected + 1,
        },
      })
    );
  };

  return (
    <>
      <div
        className={
          showContent ? "dashboard-main content-visible" : "dashboard-main"
        }
      >
        {/* <div>
          <AdminSidebar />
        </div> */}
        <div className="dashboard-body">
          <DashboardHeader toggleSidebar={toggleSidebar} />

          <Container>
            <AdminNavBar />
            <div className="dashboard-content p-2 p-md-4">
              {/* <Row>
              <Col md={4}></Col>
              <Col md={8} className="mb-4">
                <div className="text-end">
                  <Dropdown>
                    <Dropdown.Toggle id="dropdown-basic">
                      Single Family Homes
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                      <Dropdown.Item href="#/action-2">
                        Another action
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-3">
                        Something else
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Col>
            </Row> */}
              <Row>
                {total > 0 &&
                  properties.map((item) => {
                    return (
                      <Col
                        key={item?._id}
                        md={3}
                        className="mb-4"
                        onClick={() =>
                          navigate("/propertydetails", {
                            state: { property: item },
                          })
                        }
                      >
                        <div className="investPropertycard p-3 h-100">
                          {item?.property_images?.length > 0 ? (
                            <img
                              src={`${process.env.REACT_APP_SERVER_URL}/uploads/properties/original/${item?.property_images[0]?.image}`}
                              alt=""
                              className="property-img img-fluid mb-4"
                            />
                          ) : (
                            <img
                              src={require("../../../images/authbanner.png")}
                              alt=""
                              className="property-img img-fluid mb-4"
                            />
                          )}

                          <h4 className="propertyHeading">{item?.name}</h4>
                          <p className="address mb-2">
                            <span className="me-2">
                              <FontAwesomeIcon icon={faLocationPin} />
                            </span>
                            {item?.address} {item?.location}, {item?.city},{" "}
                            {item?.country}, {item?.postal_code}
                          </p>
                          <p className="expected_rate mb-0">
                            Property Value &nbsp;
                            <span className="rate">
                              ${item?.property_value}
                            </span>
                          </p>
                        </div>
                      </Col>
                    );
                  })}
              </Row>
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"..."}
                pageCount={pageCount === 1 ? 1 : pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                onPageChange={handlePageClick}
                containerClassName={"pagination justify-content-center"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"pagination_prev"}
                previousLinkClassName={"page-link"}
                nextClassName={"pagination_next"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
              />
            </div>
          </Container>
        </div>
      </div>
    </>
  );
}

export default SingleInvest;
