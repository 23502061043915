import logo from './logo.svg';
import './App.scss';
import AppRoutes from './AppRoutes';
import { Toaster } from "react-hot-toast";


function App() {
  return (
    <>
    <AppRoutes />
    <Toaster/>
    </>
  );
}

export default App;
