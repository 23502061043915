import React, { useState, useEffect, useMemo } from "react";
import AdminSidebar from "../AdminSidebar";
import DashboardHeader from "../DashboardHeader";
import { CircularProgressbar } from "react-circular-progressbar";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import { Link } from "react-router-dom";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import AdminNavBar from "../AdminNavBar";
import { getTransactionsThunk, getTransactionStatsThunk } from "../../../features/transaction/transactionThunk";
import { transactionSelector } from "../../../features/transaction/transactionSelector";
import { changeLimit, changePage } from "../../../features/transaction/transactionSlice";
import { useDispatch, useSelector } from "react-redux";
import { authSelector } from "../../../features/auth/authSelector";
import moment from "moment";
import Slider from "react-slick";
import ReactPaginate from "react-paginate";
import AddCard from "../Modal/AddCard";
import AddMoney from "../Modal/AddMoney";
import { Chart } from "react-google-charts";

function Wallet() {
  const [showContent, setShowContent] = useState(false);
  const dispatch = useDispatch();
  const [addCard, setAddCard] = useState(false);
  const [addMoney, setAddMoney] = useState(false);
  const [totalMonthMoney, setTotalMonthMoney] = useState(0);
  const [addMonthMoney, setAddMonthMoney] = useState(0);
  const [withdrawMonthMoney, setWithdrawMonthMoney] = useState(0);
  const [totalBalance, setTotalBalance] = useState(0);
  const [overviewYears, setOverviewYears] = useState([new Date().getFullYear()]);

  const toggleSidebar = () => setShowContent(!showContent);
  const percentage = 40;

  const { transactions, total, limit, page } = useSelector(transactionSelector);
  const { token, userData } = useSelector(authSelector);
  const [data, setData] = useState([["Month", "Amount"]]);
  const [balanceOverview, setBalanceOverview] = useState(0);

  const [configObject] = useState({
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      limit: limit,
      page: 1,
    },
  });

  const pageCount = useMemo(() => {
    const pageCounter = Math.ceil(total / limit);
    return pageCounter;
  }, [total, limit]);

  useEffect(() => {
    getTransactions();
    getStats();

    let years = [];
    for (var i = 2022; i <= new Date().getFullYear(); i++) {
      years.push(i)
    }
    setOverviewYears(years)
  }, []);

  const getTransactions = async () => {
    await dispatch(getTransactionsThunk({ ...configObject }));
  }

  const getStats = async (year = new Date().getFullYear()) => {
    const resp = await dispatch(getTransactionStatsThunk({
      ...configObject, params: {
        year: year
      }
    }));
    let dataArr = [["Month", "Amount"]];
    let amount = 0;
    if (resp?.payload?.status === 200) {
      setTotalMonthMoney(resp?.payload?.data?.body?.totalMonthMoney);
      setAddMonthMoney(resp?.payload?.data?.body?.addMonthMoney);
      setWithdrawMonthMoney(resp?.payload?.data?.body?.withdrawMonthMoney);
      setTotalBalance(resp?.payload?.data?.body?.totalBalance);
      const monthlyNetAmount = resp?.payload?.data?.body?.monthlyNetAmount;
      monthlyNetAmount.length > 0 && monthlyNetAmount.map((item) => {
        dataArr.push([item?.period, item?.netAmount]);
        amount += item?.netAmount;
      })
      setData(dataArr);
      setBalanceOverview(amount);
    }

  }

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  var PaymentsCards = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    centerMode: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const options = {
    chart: {
      //title: "Total Revenue",
    },
  };

  const handleYearSelect = (event) => {
    getStats(event.target.value);
  }

  const handlePageClick = async (data) => {
    await dispatch(changePage(data.selected + 1));
    await dispatch(
      getTransactionsThunk({
        ...configObject,
        params: {
          limit: limit,
          page: data.selected + 1,
        },
      })
    );
  };

  const closeAddMoney = () => {
    getStats();
    setAddMoney(false);
  }

  return (
    <>
      {addCard && (
        <AddCard
          show={addCard}
          onClose={() => setAddCard(false)}
        />
      )}
      {addMoney && (
        <AddMoney
          show={addMoney}
          onClose={closeAddMoney}
        />
      )}

      <div
        className={
          showContent ? "dashboard-main content-visible" : "dashboard-main"
        }
      >
        {/* <div>
          <AdminSidebar />
        </div> */}
        <div className="dashboard-body">
          <DashboardHeader toggleSidebar={toggleSidebar} />
          <Container>
            <AdminNavBar />
            <div className="dashboard-content p-2 p-md-4">
              <Row>
                <Col md={4}></Col>
                <Col md={8} className="mb-4">
                  <div className="d-flex justify-content-end gap-3">
                    <Button className="bg-black border-0" onClick={() => { setAddCard(true) }}>
                      {" "}
                      <FontAwesomeIcon icon={faPlus} /> Add Card
                    </Button>
                    <Button onClick={() => { setAddMoney(true) }}>
                      {" "}
                      <FontAwesomeIcon icon={faPlus} /> Add Money
                    </Button>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6} className="mb-4">
                  <div className="dashboardCard position-relative walletbalance overflow-hidden p-4 h-100">
                    <div className="d-flex align-items-center justify-content-between gap-2 flex-wrap">
                      <div className="card-headings">
                        <h4 className="mb-4">Total Balance</h4>
                        <p className="mb-2">Last Transaction</p>
                        <h3 className="text-success">$0</h3>
                        <Button className="bg-black border-0 px-5 py-2">
                          Withdraw
                        </Button>
                      </div>
                    </div>
                    <div className="text-center balance-text">
                      <h3 className="text-white fw-bold mb-1">${totalBalance}</h3>
                      <p className="text-white mb-0 fw-semibold">
                        Wallet Balance
                      </p>
                    </div>
                  </div>
                </Col>
                <Col md={6} className="mb-4">
                  <div className="dashboardCard p-4 h-100">
                    <div className="card-headings">
                      <h4 className="mb-4">Monthly Statement</h4>
                    </div>
                    <div className="d-flex align-items-center justify-content-between gap-2 flex-wrap">
                      <div>
                        <div
                          style={{ width: 100, height: 100 }}
                          className="mb-3"
                        >
                          <CircularProgressbar
                            value={withdrawMonthMoney > 0 ? ((withdrawMonthMoney / totalMonthMoney) * 100).toFixed(2) : 0}
                            text={withdrawMonthMoney > 0 ? `${((withdrawMonthMoney / totalMonthMoney) * 100).toFixed(2)}%` : 0}
                          />
                        </div>
                        <h6 className="fw-bold text-center">Withdraw</h6>
                        <p className="text-center mb-0">${withdrawMonthMoney}</p>
                      </div>
                      <div>
                        <div
                          style={{ width: 100, height: 100 }}
                          className="mb-3"
                        >
                          <CircularProgressbar
                            value={100}
                            text={`${100}%`}
                          />
                        </div>
                        <h6 className="fw-bold text-center">Total Amount</h6>
                        <p className="text-center mb-0">${totalMonthMoney}</p>
                      </div>
                      <div>
                        <div
                          style={{ width: 100, height: 100 }}
                          className="mb-3"
                        >
                          <CircularProgressbar
                            value={addMonthMoney > 0 ? ((addMonthMoney / totalMonthMoney) * 100).toFixed(2) : 0}
                            text={addMonthMoney > 0 ? `${((addMonthMoney / totalMonthMoney) * 100).toFixed(2)}%` : 0}
                          />
                        </div>
                        <h6 className="fw-bold text-center">Add Money</h6>
                        <p className="text-center mb-0">${addMonthMoney}</p>
                      </div>
                    </div>
                  </div>
                </Col>

                <Col md={6} className="mb-4">
                  <div className="dashboardCard p-4 h-100">
                    <div className="d-flex align-items-center justify-content-between mb-4">
                      <h3 className="mb-0">Card Details</h3>
                      <div>
                        <Link className="text-primary fw-semibold">
                          <FontAwesomeIcon
                            icon={faPenToSquare}
                            className="me-1"
                          />{" "}
                          Edit
                        </Link>
                      </div>
                    </div>
                    <div className="d-flex gap-4 justify-content-between">
                      <div>
                        <div className="card-headings mb-4">
                          <p className="mb-2">Gain Amount</p>
                          <h4 className="Cardheading">$4568.86</h4>
                        </div>
                        <div className="card-headings">
                          <p className="mb-2">Gain Amount</p>
                          <h4 className="Cardheading">$4568.86</h4>
                        </div>
                      </div>
                      <div>
                        <div className="card-headings mb-4">
                          <p className="mb-2">Card Number</p>
                          <h4 className="Cardheading">
                            4259 **** **** **** 1234
                          </h4>
                        </div>
                        <div className="card-headings">
                          <p className="mb-2">Card Holder</p>
                          <h4 className="Cardheading">John Smith</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>

                <Col md={6} className="mb-4">
                  <div className="dashboardCard p-4 h-100">
                    <div className="d-flex align-items-center justify-content-between mb-4">
                      <h4 className="Cardheading">Balance Overview</h4>
                      <div className="card-heading d-flex align-items-center gap-3">
                        <Form.Select aria-label="Default select example" className="pe-2 py-0 border-rounded" style={{ height: '40px' }} onChange={handleYearSelect}>
                          {
                            overviewYears.length > 0 && overviewYears.map((item) => {
                              return (<option value={item} key={item}>Yearly ({item})</option>)
                            })
                          }
                        </Form.Select>
                        <h4 className="Cardheading">${balanceOverview}</h4>
                      </div >
                    </div >
                    {
                      data.length > 1 ? <Chart
                        chartType="Bar"
                        width="100%"
                        height="400px"
                        data={data}
                        options={options}
                      />
                        : <p className="text-center">No chart data available</p>
                    }
                  </div >
                </Col >
              </Row >

              <div className="table-responsive theme-table">
                <h5 className="Cardheading">Transaction List</h5>
                <Table>
                  <thead>
                    <tr>
                      <th>Transaction Activity</th>
                      <th>Transaction Amount</th>
                      <th>Transaction Date</th>
                      <th>Transaction Time</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      total > 0 ? transactions.map((item) => {
                        return (<tr key={item?._id}>
                          <td>
                            <div className="d-flex align-items-center gap-3">
                              {
                                item?.type === 'deposit' ?
                                  <>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="18"
                                      height="17"
                                      viewBox="0 0 18 17"
                                      fill="none"
                                    >
                                      <path
                                        d="M15.8457 6.69263L16.3496 7.26494L16.3496 7.26495C16.589 7.53683 16.9959 7.53816 17.2368 7.26718L17.0055 7.06152L17.2368 7.26717C17.4581 7.01829 17.4587 6.62795 17.2388 6.3782L17.2388 6.37819L15.6812 4.60925C15.6747 4.60185 15.6697 4.59686 15.668 4.59517L15.6689 4.59601C15.6709 4.59805 15.6753 4.60258 15.6807 4.60863C15.4417 4.33795 15.0354 4.33617 14.7945 4.60638C14.794 4.60694 14.7935 4.60752 14.793 4.60811L14.7926 4.60859L14.792 4.60923L14.785 4.61712L14.785 4.61716L13.2344 6.37818L13.2344 6.37818C13.0145 6.62789 13.0151 7.01824 13.2364 7.26716L15.8457 6.69263ZM15.8457 6.69263L15.8457 13.0423C15.8457 13.3635 15.6075 13.6904 15.2366 13.6904C14.8657 13.6904 14.6275 13.3635 14.6275 13.0423L14.6275 6.69263L14.1236 7.26492L14.1236 7.26493C13.8842 7.53681 13.4773 7.53814 13.2364 7.26717L15.8457 6.69263ZM14.8056 4.59477C14.8047 4.59566 14.8026 4.59774 14.7997 4.60081C14.8019 4.59844 14.8037 4.59664 14.8047 4.59559L14.8056 4.59477Z"
                                        fill="#61C277"
                                        stroke="#61C277"
                                        strokeWidth="0.619048"
                                      />
                                      <path
                                        d="M6.9842 5.24017L5.48698 3.73566L5.48698 15.2766C5.48698 15.7681 5.08858 16.1665 4.5971 16.1665C4.10562 16.1665 3.70722 15.7681 3.70722 15.2766L3.70722 3.73567L2.20999 5.24015L2.20999 5.24016C1.86329 5.5885 1.29985 5.58984 0.951512 5.24314C0.603071 4.89637 0.601908 4.33292 0.948488 3.98465L1.16789 4.20298L0.948492 3.98465L3.95905 0.959505L3.96635 0.952162L3.96748 0.951036C3.96821 0.950309 3.96893 0.949604 3.96962 0.948925C4.31682 0.60365 4.8777 0.604623 5.22395 0.948333C5.22551 0.949842 5.22721 0.951526 5.22903 0.95338L8.24571 3.98468L8.24571 3.98468C8.59235 4.33301 8.59107 4.89643 8.24269 5.24317C7.89434 5.58987 7.33091 5.58853 6.98422 5.24019L6.9842 5.24017ZM3.96722 0.951323C3.96694 0.951605 3.96665 0.95189 3.96636 0.95218L3.96722 0.951323Z"
                                        fill="#61C277"
                                        stroke="#61C277"
                                        strokeWidth="0.619048"
                                      />
                                    </svg>
                                    <span className="text-capitalize">{item?.type}</span>
                                  </>
                                  :
                                  <>
                                    <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M15.8457 10.1643L16.3496 9.592L16.3496 9.59198C16.589 9.3201 16.9959 9.31878 17.2368 9.58976L17.0055 9.79541L17.2368 9.58976C17.4581 9.83865 17.4587 10.229 17.2388 10.4787L17.2388 10.4787L15.6812 12.2477C15.6747 12.2551 15.6697 12.2601 15.668 12.2618L15.6689 12.2609C15.6709 12.2589 15.6753 12.2544 15.6807 12.2483C15.4417 12.519 15.0354 12.5208 14.7945 12.2506C14.794 12.25 14.7935 12.2494 14.793 12.2488L14.7926 12.2483L14.792 12.2477L14.785 12.2398L14.785 12.2398L13.2344 10.4788L13.2344 10.4788C13.0145 10.229 13.0151 9.8387 13.2364 9.58978L15.8457 10.1643ZM15.8457 10.1643L15.8457 3.81462C15.8457 3.49339 15.6075 3.16655 15.2366 3.16655C14.8657 3.16655 14.6275 3.49339 14.6275 3.81462L14.6275 10.1643L14.1236 9.59201L14.1236 9.592C13.8842 9.32012 13.4773 9.31879 13.2364 9.58976L15.8457 10.1643ZM14.8056 12.2622C14.8047 12.2613 14.8026 12.2592 14.7997 12.2561C14.8019 12.2585 14.8037 12.2603 14.8047 12.2613L14.8056 12.2622Z" fill="#FF000F" stroke="#FF000F" strokeWidth="0.619048" />
                                      <path d="M6.9842 11.6168L5.48698 13.1213L5.48698 1.58036C5.48698 1.08888 5.08858 0.690476 4.5971 0.690476C4.10562 0.690476 3.70722 1.08888 3.70722 1.58036L3.70722 13.1213L2.20999 11.6168L2.20999 11.6168C1.86329 11.2684 1.29985 11.2671 0.951512 11.6138C0.603071 11.9606 0.601908 12.524 0.948488 12.8723L1.16789 12.6539L0.948492 12.8723L3.95905 15.8974L3.96635 15.9048L3.96748 15.9059C3.96821 15.9066 3.96893 15.9073 3.96962 15.908C4.31682 16.2533 4.8777 16.2523 5.22395 15.9086C5.22551 15.9071 5.22721 15.9054 5.22903 15.9036L8.24571 12.8723L8.24571 12.8723C8.59235 12.5239 8.59107 11.9605 8.24269 11.6138C7.89434 11.2671 7.33091 11.2684 6.98422 11.6167L6.9842 11.6168ZM3.96722 15.9056C3.96694 15.9053 3.96665 15.905 3.96636 15.9048L3.96722 15.9056Z" fill="#FF000F" stroke="#FF000F" strokeWidth="0.619048" />
                                    </svg>
                                    <span className="text-capitalize">{item?.via === 'investment' ? 'Invest' : item?.type}</span>
                                  </>
                              }

                            </div>
                          </td>
                          <td>${item?.amount}</td>
                          <td>{moment(item?.createdAt).format('DD MMMM, YYYY')}</td>
                          <td>{moment(item?.createdAt).format('hh:mm A')}</td>
                          <td>
                            <span className={`dashboard_badge text-capitalize ${item?.status === 'completed' ? 'success' : 'warning'}`}>
                              {item?.status}
                            </span>
                          </td>
                        </tr>)
                      })
                        :
                        <tr><td colSpan={5} className="text-center">No record found</td></tr>
                    }
                  </tbody>
                </Table>
              </div>

              <div className="mt-4">
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  breakLabel={"..."}
                  pageCount={pageCount === 1 ? 1 : pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={3}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination justify-content-center"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"pagination_prev"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"pagination_next"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                />
              </div>

            </div >
          </Container >
        </div >
      </div >
    </>
  );
}

export default Wallet;
